import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NetworkService } from '../network/_services/network';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ECarbinetService {

  constructor(
    private http: HttpClient
  ) { }

  getAllDivisionEcarbinetFilesFolders() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.GET_ALL_DIVISION_ECARBINET_FILES_FOLDERS}`,{}, {headers});
  }

  getAllDivisionEcarbinetMemoFolders() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.GET_ALL_DIVISION_ECARBINET_MEMOS_FOLDERS}`,{}, {headers});
  }

  getAllEcarbinetFoldersByStatus(payload) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.GETALLECARBINETFOLDERSBYSTATUS}`, payload, {headers});
  }

  getAllActiveAndFinalizedECarbinetFolders() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.get(`${environment.host}${NetworkService.GETALLECARBINETFOLDERSACTIVEORFINALIZED}`, {headers});
  }

  archiveAnECarbinetFolder(payload) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.ARCHIVEANECARBINETFOLDER}?folderId=${payload}`, payload, {headers});
  }

  getSingleECarbinetFolder(id) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.GETALLECARBINETFOLDERSBYSTATUS}`, {FolderId: id}, {headers});
  }

  getSingleECarbinetMemoFolder(id) {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.GETSINGLEECARBINETMEMOFOLDERS}`, {FolderId: id}, {headers});
  }

  getAllEcarbinetMemosFolder() {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + User.getCurrentUser().token);
    return this.http.post(`${environment.host}${NetworkService.GETALLECARBINETMEMOFOLDERS}`, {}, {headers});
  }
}
