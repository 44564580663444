import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-route-document-modal',
  templateUrl: './route-document-modal.component.html',
  styleUrls: ['./route-document-modal.component.scss']
})
export class RouteDocumentModalComponent implements OnInit {

  @Input() UsersList: Array<any>;
  @Input() SearchFn: () => any;

  constructor() { }

  ngOnInit() {
  }

}
