import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '../helpers';

@Component({
  selector: 'app-simple-file-upload',
  templateUrl: './simple-file-upload.component.html',
  styleUrls: ['./simple-file-upload.component.scss']
})
export class SimpleFileUploadComponent implements OnInit {

  
  @ViewChild('files', {static: false}) files: ElementRef;
  @Input() totFileSize: number;
  @Input() totFileSizeString: string;
  @Input() max_number_of_files: number = 10;
  @Input() selected_files: Array<File> = new Array();
  @Input() file_types_url = Helpers.file_types_urls;
  @Input() multiple = false;
  @Input() disable = false;
  @Output() showMessage: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  browseFiles(){
    var fin_files: HTMLInputElement = this.files.nativeElement;
    fin_files.click();
  }

  filesChange(){
    var files = this.files.nativeElement;
    var fileError: boolean = false;
    if(files.files){
      var total_file_count = this.selected_files.length + files.files.length;
      if(total_file_count <= this.max_number_of_files){
        for(var i = 0; i < files.files.length; i++){
          if(this.file_types_url[files.files[i].type]){
            this.selected_files.push(files.files[i]);
            var file_size = +((files.files[i].size/1024)/1024);
            this.totFileSize += file_size;
            this.totFileSizeString = this.totFileSize.toFixed(2) + "MB";
          }else{
            fileError = true;
            files.value = "";
          }
        }
        if(fileError){
          this.showMessage.emit({title: "Skipped files", message: "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format"});
        }
      }else{
        this.showMessage.emit({title: "Skipped files", message: "You have reached the maximum number of files"});
      }
      files.value = "";
    }else{
      this.selected_files = new Array(); 
    }
  }
  removeFiles(){
    var files = this.files.nativeElement;
    files.value = "";
    this.selected_files = new Array();
  }
}
