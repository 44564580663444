import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { NetworkService } from '../network/_services/network';

@Directive({
  selector: '[appRbacDisallow]'
})
export class RbacDisallowDirective {
  allowedRoles: string[];
  user: any;

constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _network: NetworkService) { 
    this.user = this._network.getUserDetails();
    console.log(this.user);
}

@Input()
set rbacAllow(allowedRoles: string[]) {
  this.allowedRoles = allowedRoles;
  this.showIfUserAllowed();
}

showIfUserAllowed() {
  if(!this.user || !this.user.uRoles || !this.allowedRoles || this.allowedRoles.length == 0) {
      console.log('here')
      this.viewContainer.clear();
      return;
  }
  const userRoles = this.user.uRoles;
  if(typeof userRoles === 'string') {
      if(this.allowedRoles.includes(userRoles)) {
        this.viewContainer.clear();
          return;
      }
  } else if (typeof userRoles == 'object') {
      const isUserAllowed = userRoles.filter(value => this.allowedRoles.includes(value)).length > 0
      if(isUserAllowed) {
        this.viewContainer.clear();
          return;
      }
  }
  this.viewContainer.createEmbeddedView(this.templateRef);
  return;
}
}
