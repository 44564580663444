import { Injectable } from '@angular/core';
import { NetworkService } from '../network/_services/network';
import { InterMinistrySubmissions } from '../models/inter-ministry-submissions';
import { AlertHelper } from '../helpers/alert-helper';
import { MatDialog } from '@angular/material';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { User } from '../models/user';
import { map, catchError } from 'rxjs/operators';
import { Helpers } from '../helpers';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import moment from 'moment';

@Injectable()
export class InterMinistryService { //this guy sends, manipulates, and makes data available for the requesting components
submissions: InterMinistrySubmissions;
untreated: any;
alert: AlertHelper
url = environment.g2g_url;

    constructor(private _http: HttpClient, private _dialog: MatDialog,private router: Router) {
        this.alert = new AlertHelper(_dialog);
    }

    getStats(type: string, currentPage: number, pageSize: number) {
        return this.request(this.url + 'g2g/fetch-user-archived-docs', {PageNumber: currentPage, PageSize: pageSize, type: type});
    }



    private request(link: string, body: any) {
        let headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("Authorization", "Bearer " + User.getCurrentUser().token);
        let requestBody = this.jsonToURLEncoded(body);

       return this._http.post(link, requestBody, {headers, observe: 'response'}).pipe(
           map(response => {
            return response;
           }),
           catchError((error: HttpErrorResponse) => {
            if(error.status == 401){
                localStorage.removeItem(Helpers.STORAGE_TAG);
                this.router.navigate(['/login']);
            }
            return Observable.throw(error);
           })
       )
    }

    private jsonToURLEncoded(jsonString){
        return Object.keys(jsonString).map(function(key){
            return encodeURIComponent(key) + '=' + encodeURIComponent(jsonString[key]);
        }).join('&');
    }

    public tranformDate(date: string) {
        return moment(date).format("MMMM Do YYYY");
    }

    public isToday(date: string) {
        let today = new Date();
        let dte = moment(date);
        return dte.day == today.getDay && dte.month == today.getMonth && dte.year == today.getFullYear
    }

}
