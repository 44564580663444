import {
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[appDragDrop]",
})
export class DragDropDirective {
  constructor() {}

  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding("style.background-color") background = "#fff";
  @HostBinding("style.opacity") opacity = "1";
  @HostBinding("style.border") border = "1px dotted #fff";

  //Dragover listener
  @HostListener("dragover", ["$event"]) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#9ecbec";
    this.opacity = "0.8";
    this.border = "1px dashed #0cc5a7";
  }

  //Dragleave listener
  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#fff";
    this.opacity = "1";
    this.border = "1px dotted #fff";
  }

  //Drop listener
  @HostListener("drop", ["$event"]) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#fff";
    this.border = "1px dotted #fff";
    this.opacity = "1";
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }
}
