import { User } from 'src/app/models/user';
import { Helpers } from './../../helpers';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {

  openRoutes = ['login', 'register', 'register/done', 'reset-password'];
  alertHelper: AlertHelper;

  constructor(protected router: Router, private dialog: MatDialog) {
    this.alertHelper = new AlertHelper(dialog);
  }

     canActivate(route: ActivatedRouteSnapshot) {
      var currentUser = User.getCurrentUser();
      if(this.openRoutes.indexOf(route.routeConfig.path) >= 0){
        if(!currentUser){
          return true;
        }
        this.router.navigate(['/']);
        return false;
      }
      if(route.routeConfig.path == "verify_email"){
        if(!currentUser){
          //Check for verifying email in storage
          var v_email = localStorage.getItem(Helpers.VERIFY_EMAIL_DETAIL);
          if (v_email) {
            return true;
          }
        }
        this.router.navigate(['/']);
        return false;
      }
      if (currentUser) {
        // check if route is restricted by role
        if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }
      // not logged in so redirect to login page
      this.router.navigate(['/login']);
      return false;
    }
}
