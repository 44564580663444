import { Component, OnInit, ViewChild, ElementRef, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { Helpers } from '../../helpers';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertHelper } from '../../helpers/alert-helper';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { NetworkService } from '../../network/_services/network';
import { UserIdleService } from 'angular-user-idle';
import { WindowRef } from '../../helpers/windowref';
import { Utils } from '../../helpers/utils';
import $ from 'jquery';
import {HttpResponse, HttpClient} from '@angular/common/http';
import moment from 'moment';
import { splitAtPeriod } from '@angular/compiler/src/util';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
@Component({
  selector: 'app-ministry-correspondence',
  templateUrl: './ministry-correspondence.component.html',
  styleUrls: ['./ministry-correspondence.component.scss']
})
@Injectable({ providedIn: 'root' })
export class MinistryCorrespondenceComponent implements OnInit {

  sendFilesView = true;
  sendMemoView = false;
  sendCircularView = false;
  sendMinutesView = false;
  viewStatsView = false;
  upload_process: boolean = false;
  upload_process_page: number = 0;
  selectedFiles: Array<File> = new Array();
  selectedDummyFiles: Array<File> = new Array();
  file_types_url = Helpers.file_types_urls;
  max_number_of_files: number = 5;
  max_files_size: number = 5;
  max_file_size_exceed: boolean = false;
  totFileSize: number = 0;
  totFileSizeString: string = "";

  upload_err: boolean = false;
  upload_err_text: string = "";

  dropZone: HTMLDivElement;
  mock_files: HTMLInputElement;

  preloader: boolean = true;
  preload_err: boolean = false;

  correspondence_no: string = "";
  show_confidentiality_warning: boolean = false;

  // dashboard_vars = { ministries_agencies: [ { id: "", name: "", departments: [ { name: "", id: "" }, { name: "", id: "" } ] } ], positions: [ { id: "", title: "" } ], max_number_of_files: 5, max_files_size: 5, max_recepients: 5, pending_files: { count: 17, today: 1 }, uploaded_files: { count: 17, today: 1 }, treated_files: { count: 17, today: 1 }, notifications: [ { id: 1, title: "", message: "" } ] };
  dashboard_vars: DashboardValues = new DashboardValues();

  confidentiality_levels = [{id: "1", name:"Unclassified"},{id: "2", name:"Confidential"},{id: "3", name:"Secret"}, {id: "4", name:"Top Secret"}]

  ministries: {id: number, name: string, departments: {id: number, name: string, txFee: string}[], positions: {id: number, title: string }[]}[] = new Array();
  departments: {name: string; id: string;}[];
  departments2: {id: number, name: string, txFee: string}[];
  positions: {id: number, title: string }[];

  // selected_ministry: number;
  // selected_department: number;
  // selected_position: number;
  // selected_recepients = [];

  submission: {ministry_id: number, department_id: number, position: number, subject: string, coress: number, confidentialityLevel: string, recepient_ids:any[], ministry_ids: number[], recipientId: any;} = {ministry_id: null, department_id: null, position: null, subject: "", coress: null, confidentialityLevel: "1", recepient_ids: [], ministry_ids: [], recipientId: null};


  selected_ministry: {id: number, name: string, departments: {id: string, name: string}[]}[];
  selected_department: {id: number, name: string, txFee: string};


  selected_department_text: string = "";
  selected_position_text: string = "";
  selected_ministry_text: string = "";




  showTokenInput: boolean = false;
  tokenInputRecepientText: string = "";

  submit_loader: boolean = false;
  submit_loader_text:string = "";
  sub_err: boolean = false;
  sub_err_text: string = "";


  prev_coress_async: boolean = false;
  prev_coresspondences: {id: string, name: string}[] = [];
  selected_prev_coress: {id: string, name: string}[] = [];
  prev_correspondence_checked: boolean = false;
  prev_coress_err: boolean = false;
  prev_coress_err_text: string = "";

  payment_done: boolean = false;
  track_file_shadow: boolean = false;
  show_sidebar: boolean = false;
  alertHelper: AlertHelper;

  public fieldsM: Object = { text: 'name', value: 'id' };
  // Set the popup list height
  // set the placeholder to the MultiSelect input
  public placeholderM: string = 'Select minsitries';
  // set value of enableGroupCheckBox to true
  enableGroupCheckBoxM: boolean = true;
  // set the placeholder to the filterbar
  filterBarPlaceholderM: string = "Search by minsitry name";
  // set the value of showSelectAll as true

  config = {
    displayKey:"name", //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: this.ministries.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }


  @ViewChild('mafiles', {static: false}) mafiles: ElementRef; //Main Files Element Ref
  @ViewChild('mofiles', {static: false}) mofiles: ElementRef; //Mock Files Element Ref
  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) document, private router: Router, private dialog: MatDialog, private network: NetworkService, private userIdle: UserIdleService, private winRef: WindowRef, private cd: ChangeDetectorRef , private http: HttpClient) {
    this.alertHelper = new AlertHelper(dialog);
   }

  ngOnInit() {
    this.loadMinistries();
  }


  loadMinistries(){
    var mins = localStorage.getItem(Helpers.MIN_AG);
    var min_ag = JSON.parse(mins);
    this.ministries = min_ag.data;
    console.log(this.ministries);
  }

  setSendFilesView() {
    this.sendFilesView = true;
    this.sendMemoView = false;
    this.sendCircularView = false;
    this.sendMinutesView = false;
    this.viewStatsView = false;
  }

  setSendMemoView() {
    this.sendFilesView = false;
    this.sendMemoView = true;
    this.sendCircularView = false;
    this.sendMinutesView = false;
    this.viewStatsView = false;
  }

  setSendCircularView() {
    this.sendFilesView = false;
    this.sendMemoView = false;
    this.sendCircularView = true;
    this.sendMinutesView = false;
    this.viewStatsView = false;
  }

  setSendMinutesView() {
    this.sendFilesView = false;
    this.sendMemoView = false;
    this.sendCircularView = false;
    this.sendMinutesView = true;
    this.viewStatsView = false;
  }

  setViewStatsView() {
    this.sendFilesView = false;
    this.sendMemoView = false;
    this.sendCircularView = false;
    this.sendMinutesView = false;
    this.viewStatsView = true;
  }


  // Methods copied //


  zoneDragOver(event: DragEvent, zone: HTMLDivElement){
    event.preventDefault();
    zone.classList.add('dragged');
  }

  zoneDragLeave(event: DragEvent, zone: HTMLDivElement){
    event.preventDefault();
    zone.classList.remove('dragged');
  }

  zoneDrop(event: DragEvent, zone: HTMLDivElement){
    event.preventDefault();
    var mfiles: HTMLInputElement = this.mofiles.nativeElement;
    zone.classList.remove('dragged');
    mfiles.files = event.dataTransfer.files;
    this.mockFilesChange();
  }

  modalShadow(){
    return this.upload_process;
  }
  uploadFileClick(){
    this.show_sidebar = false;
    if(this.track_file_shadow){
      return;
    }
    if(!this.upload_process){
      this.resetFSFields();
      this.upload_process = true;
      this.upload_process_page = 0;
    }
  }

  closeUploadProcess(){
    this.upload_process = false;
    this.cd.detectChanges();
    this.resetFSFields();
  }

  displayUploadProcess() {
    this.upload_process = true;
  }

  resetFSFields(){
    this.payment_done = false;
    this.upload_process_page = 0;
    this.selectedFiles = new Array();
    this.selectedDummyFiles = new Array();
    this.setFileSizes();
    this.submission.recepient_ids = [];
    this.submission.coress = null;
    this.submission.position = null;
    this.submission.subject = "";
    this.submission.ministry_id = null;
    this.selected_department = null;
    this.selected_ministry = null;
    this.selected_department_text = "";
    this.selected_ministry_text = "";
    this.selected_position_text = "";
    this.tokenInputRecepientText = "";
    this.upload_err = false;
    this.sub_err = false;
    this.submit_loader = false;
    this.departments2 = null;
    this.positions = null;
    this.selected_prev_coress = null;
    this.prev_coresspondences = [];
    this.prev_correspondence_checked = false;
    this.prev_coress_err = false;
    this.prev_coress_async = false;
    this.show_confidentiality_warning = false;
    this.submission.confidentialityLevel = "1";
    this.cd.detectChanges();
  }

  coressChange(){
    this.prev_coress_err = false;
    if(this.submission.coress == 1){
      if(this.submission.department_id){
        this.prev_correspondence_checked = true;
        this.prev_coress_async = true;
        this.network.sendRequest({department: this.submission.department_id}, NetworkService.PREV_CORESS).subscribe((res)=>{
          this.prev_coress_async = false;
          if(!res['error']){
            this.prev_coresspondences = JSON.parse(res['response']);
          }else{
            this.prev_coress_err = true;
            this.prev_coress_err_text = res['response'];
          }
        },(error)=>{
          this.prev_coress_async = false;
          this.prev_coress_err = true;
          this.prev_coress_err_text = "Sorry an unexpected error occured.";
        })
      }else{
        this.prev_correspondence_checked = false;
        this.prev_coresspondences = [];
      }
    }else if(this.submission.coress == 0){
      this.selected_prev_coress = [];
      this.prev_correspondence_checked = false;
    }else{
      this.selected_prev_coress = [];
      this.prev_coresspondences = [];
    }
  }

  confidentialityChange(){
    if(!isNaN(parseInt(this.submission.confidentialityLevel))){
      var clevel = parseInt(this.submission.confidentialityLevel);
      if(clevel > 1){
        this.show_confidentiality_warning = true;
      }else{
        this.show_confidentiality_warning = false;
      }
    }
  }


  browseFiles(){
    var mock_files: HTMLInputElement = this.mofiles.nativeElement;
    mock_files.click();
  }

  getFileIcon(type){
    return this.file_types_url[type]
  }

  mockFilesChange(){
    var mock_files: HTMLInputElement = this.mofiles.nativeElement;
    var fileError: boolean = false;
    if(mock_files.files){
      var total_file_count = this.selectedFiles.length + mock_files.files.length;
      if(total_file_count <= this.max_number_of_files){
        for(var i = 0; i < mock_files.files.length; i++){
          var findFile = this.selectedFiles.find((f)=>{
            return f.name == mock_files.files[i].name;
          });
          if(!findFile){
            if(this.file_types_url[mock_files.files[i].type]){
              this.selectedFiles.push(mock_files.files[i]);
              var file_size = +((mock_files.files[i].size/1024)/1024);
              this.totFileSize += file_size;
              this.totFileSizeString = this.totFileSize.toFixed(2) + "MB";
              console.log(this.selectedFiles);
            }else{
              fileError = true;
            }
          }else{
            fileError = true;
          }
        }
        if(fileError){
          this.alertHelper.showOkAlert("Skipped files", "Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format")
        }
      }else{
        this.alertHelper.showOkAlert("Skipped files", "You have reached the maximum number of files")
      }
      mock_files.value = "";
    }
  }

  removeFile(file: File){
    this.selectedFiles = this.selectedFiles.filter((f)=>{
      return f.name != file.name;
    })
    this.setFileSizes();
  }

  removeFileDummy(file: File){
    this.selectedDummyFiles = this.selectedDummyFiles.filter((f)=>{
      return f.name != file.name;
    });
    this.selectedFilesPathAndNames = this.selectedFilesPathAndNames.filter((f)=> {
      return f['fileName'] != file.name;
    });
    this.setFileSizes();
  }

  setFileSizes(){
    var file_size = 0;
    this.selectedFiles.forEach((f)=>{
      file_size += ((f.size/1024)/1024);
    });
    this.selectedDummyFiles.forEach((f)=>{
      file_size += ((f.size/1024)/1024);
    });
    this.totFileSize = file_size;
    this.totFileSizeString = this.totFileSize.toFixed(2) + "MB";
  }

  checkSendFiles(){
    return !(this.totFileSize <= this.max_files_size && this.selectedFiles.length <= this.max_number_of_files);
  }

  showAttachRecepient(){
    if(!this.checkSendFiles()){
      this.upload_process_page = 1;
      var __this = this;
      setTimeout(() => {
        $('#reps-tag').on("focusout", function(e) {
          setTimeout(() => {
            if ($(this).has(document.activeElement).length == 0) {
              //close token input
              __this.anotherFocus();
            }
          }, 500);

        })
      }, 500);
    }
  }


  private isValidEmail(control: FormControl) {
    if (!Utils.isValidEmail(control.value)) {
        return {
            'isValidEmail@': true
        };
    }
    return null;
  }
  public validators = [this.isValidEmail];

  public errorMessages = {
      'isValidEmail@': 'The email is invalid'
  };

  onFocusForToken() {
    this.showTokenInput = true;
    setTimeout(() => {
      var tokenInput = document.getElementById('reps-token');
      tokenInput.focus();
    }, 300);

  }

  public onRemoveTag(event){
    console.log(event)
    if(this.submission.recepient_ids.length > 0){
      if(this.submission.recepient_ids.length > 1){
        this.tokenInputRecepientText = this.submission.recepient_ids[0].value + " + " + (this.submission.recepient_ids.length - 1) + " more.";
      }else{
        this.tokenInputRecepientText = this.submission.recepient_ids[0].value;
      }
    }else{
      this.tokenInputRecepientText = "";
    }
  }

  public onBlur() {
    if(this.submission.recepient_ids.length > 0){
      if(this.submission.recepient_ids.length > 1){
        this.tokenInputRecepientText = this.submission.recepient_ids[0].value + " + " + (this.submission.recepient_ids.length - 1) + " more.";
      }else{
        this.tokenInputRecepientText = this.submission.recepient_ids[0].value;
      }
      //this.showTokenInput = false;
    }
    // else{
    //   this.showTokenInput = false;
    // }
  }

  public anotherFocus(){
    if(this.showTokenInput){
      setTimeout(()=>{
        if(this.submission.recepient_ids.length > 0){
          if(this.submission.recepient_ids.length > 1){
            this.tokenInputRecepientText = this.submission.recepient_ids[0].value + " and " + (this.submission.recepient_ids.length - 1) + " more.";
          }else{
            this.tokenInputRecepientText = this.submission.recepient_ids[0].value;
          }
          this.showTokenInput = false;
        }
      }, 500)
    }
  }



  onBlurTokenHolder(event){
    //console.log(event.target)
    //this.showTokenInput = false;
  }

  // ministrySelected(event){
  //   console.log(event);
  //  var mini = this.dashboard_vars.ministries_agencies.find((m) =>{
  //    return m.id == this.submission.ministry.toString();
  //  });
  //  this.selected_ministry_text = mini.name;
  //  this.departments = mini.departments;
  //  console.log(this.submission.ministry);
  // }

  show_department: boolean = true;
  disable_department: boolean = false;
  disable_users: boolean = false;
  emptyUserOption: boolean = false;
  checkSelection() {
    if(this.selected_ministry.length <= 1) {

    } else {
      this.show_department = false;
      this.departments2 = null;
      this.disable_department = true;
      this.selected_ministry_text = this.selected_ministry_text + ' + ' + (this.selected_ministry.length - 1) + ' more';
      this.disable_users = true;
      this.emptyUserOption = true;
      this.selected_department_text = '';
    }
    // console.log("Hit!!!");
  }
  ministrySelected2(event){
    if(event.value){
      this.submission.ministry_id = event.value.id;
      var mini = this.ministries.find((m) =>{
        return m.id == this.selected_ministry[0].id;
      });
      console.log(this.selected_ministry);
      if (this.selected_ministry.length == 1) {
        this.show_department = false;
        this.departments2 = mini.departments;
        this.selected_ministry_text = mini.name;
        this.disable_department = false;
        this.disable_users = false;
      }else if (this.selected_ministry.length == 0) {
        this.show_department = false;
        this.departments2 = null;
        this.selected_ministry_text = '';
        this.disable_department = false;
        this.disable_users = false;
      } else if (this.selected_ministry.length > 1) {
        this.show_department = false;
        this.departments2 = null;
        this.disable_department = true;
        this.selected_ministry_text = this.selected_ministry_text + ' + ' + (this.selected_ministry.length - 1) + ' more';
        this.disable_users = true;
        this.emptyUserOption = true;
        this.selected_department_text = '';
      }
      // var deps: {id: number, name: string}[] = mini.departments;
      // setTimeout(() => {
      //   this.departments2 = [...this.departments2]
      // }, 500);

      //refresh the deparment dropdown
      setTimeout(() => {
        this.show_department = true;
      }, 100);

      // setTimeout(() => {
      //   this.departments2 = [...mini.departments];
      //   setTimeout(() => {
      //     this.selected_department = {...this.selected_department, id: mini.departments[0].id, name: mini.departments[0].name};
      //   }, 1000);
      // }, 100);
      this.positions = mini.positions;
      this.submission.position = null;
      // this.selected_department = null
      // var department;
      // delete this.selected_department;
      this.submission.department_id = null;
      // this.coressChange();
    }else{
      this.submission.ministry_id = null;
      this.submission.department_id = null;
      this.submission.position = null;
      this.selected_department = null;
      this.selected_ministry = null;
      this.selected_ministry_text = "";
      this.selected_department_text = "";
      this.selected_position_text = ""
      this.positions = [];
      this.departments2 = [];
      this.departmentedSelected2();
    }
  }

  departmentedSelected(){
    var dep = this.departments.find((d)=>{
      return d.id == this.submission.department_id.toString()
    });
    this.selected_department_text = dep.name;
  }

  departmentedSelected2(event?){
    if(event && event.value){
      this.submission.department_id = event.value.id;
      var dep = this.departments2.find((d)=>{
        return d.id == this.submission.department_id
      });
      this.selected_department = dep;
      this.selected_department_text = dep.name;
      this.fetchDepartmentUsers(this.selected_ministry[0].id, dep.id);
    }else{
      this.selected_department = null;
      this.submission.department_id = null;
      this.selected_department_text = "";
      this.show_department = false;
      setTimeout(() => {
        this.show_department = true;
      }, 100);
    }
    //this.submission.coress = null;
    this.coressChange();
  }

  fetchedUsers: any;
  department_users_load_async = false;
  fetchDepartmentUsers(ministryId, departmentId) {
    this.department_users_load_async = true;
    this.network.sendRequest({mid: ministryId, did: departmentId}, NetworkService.GET_DEPARTMENT_USERS_2).subscribe((result) => {
      if (!result['error']) {
      this.department_users_load_async = false;
      this.fetchedUsers = JSON.parse(result['response']);
      } else {
        this.alertHelper.showYesNoAlert('Error', 'An error occured while loading selected department users, click YES to retry', ()=> {
          this.fetchDepartmentUsers(this.selected_ministry[0].id, this.selected_department.id);
        });
      }
    });
  }

  positionSelected(){
    var pos = this.fetchedUsers.find((p)=>{
      return p.id == this.submission.recipientId;
    });
    this.selected_position_text = pos.name;
  }

  getSelectedMinistry(){
    if(this.submission.ministry_id == null && this.submission.ministry_ids.length == 0){
      return null
    }
    var min = this.ministries.find((m)=>{
      return m.id == this.submission.ministry_id
    });
    return min;
  }
  getSelectedDepartment(){
    if(this.submission.department_id == null){
      return null
    }
    var dep = this.departments2.find((d)=>{
      return d.id == this.submission.department_id
    });
    return dep;
  }
  getSelectedPosition(){
    if(this.submission.position == null){
      return null
    }
    var pos = this.positions.find((p)=>{
      return p.id == this.submission.position
    });
    return pos;
  }

  sumbitFiles(){
    // Get selected ministry
    this.showFSLoader("Submitting...");
    this.sub_err = false;
    if (this.selected_ministry.length > 0) {
      if (this.selected_ministry.length == 1) {
        this.submission.ministry_id = this.selected_ministry[0].id;
        if (this.selected_department == null) {
          this.sub_err = true;
          this.sub_err_text = "Please select a department";
        } else {
          this.submission.department_id = this.selected_department.id;
          if (this.submission.recipientId == null) {
            this.sub_err = true;
            this.sub_err_text = "Please select a recipient";
          } else {
            this.submission.recepient_ids.push(this.submission.recipientId);
            if(this.submission.subject.trim() == ""){
              this.sub_err = true;
              this.sub_err_text = "Please enter a subject";
            }
          }
        }
      } else {
        this.submission.ministry_ids = [];
        this.selected_ministry.forEach((element)=> {
          this.submission.ministry_ids.push(element.id);
          console.log(this.submission.ministry_ids);
        });
        this.submission.ministry_id = 0;
        this.submission.recipientId = 0;
        this.submission.department_id = 0;
        if(this.submission.subject.trim() == ""){
          this.sub_err = true;
          this.sub_err_text = "Please enter a subject";
        }
      }
    } else {
      this.sub_err = true;
      this.sub_err_text = "Please select a ministry";
    }
    // var p_coress = [];
    // if(!this.getSelectedMinistry()){
    //   this.sub_err = true;
    //   this.sub_err_text = "Please select a ministry";
    // }else if(!this.getSelectedDepartment()){
    //   this.sub_err = true;
    //   this.sub_err_text = "Please select a department";
    // }else if(!this.getSelectedPosition()){
    //   this.sub_err = true;
    //   this.sub_err_text = "Please select a position";
    // }else if(this.submission.recepients.length <= 0){
    //   this.sub_err = true;
    //   this.sub_err_text = "Please enter atleast one recipient";
    // }else if(this.submission.subject.trim() == ""){
    //   this.sub_err = true;
    //   this.sub_err_text = "Please enter a subject";
    // }else if(this.submission.coress == null){
    //   this.sub_err = true;
    //   this.sub_err_text = "Please select yes or no to if you have a previous correspondence.";
    // }else if(this.submission.coress == 1){
    //   if(this.selected_prev_coress.length <= 0 && this.prev_coresspondences.length > 0){
    //     this.sub_err = true;
    //     this.sub_err_text = "Please select atleast one previous correspondence.";
    //   }else if(this.selected_prev_coress.length <= 0 && this.prev_coresspondences.length <= 0){
    //     this.submission.coress = 0;
    //   }else{
    //     this.selected_prev_coress.forEach((pc)=>{
    //       p_coress.push(pc.id);
    //     })
    //   }
    // }
    if(!this.sub_err){
      var _sub: any =  JSON.stringify(this.submission);
      var sub = JSON.parse(_sub);
      var arr_reps = Array();
      this.submission.recepient_ids.forEach((r) =>{
        arr_reps.push(r.value);
      });
      sub.recepients = arr_reps;
      sub.file_details = JSON.stringify(this.selectedFilesPathAndNames);
      // sub.prev_coress = p_coress;
      this.network.filesUploadNoKey(this.selectedFiles, NetworkService.SUBMIT_FILES_G2G, sub).subscribe((res)=>{
        this.handleSubmissionProgress(res);
      }, (error) =>{
        this.dismissFSLoader();
        this.sub_err = true;
        this.submission.ministry_ids = [];
        this.submission.recepient_ids = [];
        this.sub_err_text = "Sorry an unexpected error occured. Click submit to retry.";
        this.alertHelper.showOkAlert("Error", this.sub_err_text);

      });
      // this.alertHelper.showOkAlert('Error', 'Working perfectly');
    }else{
      this.dismissFSLoader();
      // this.alertHelper.showOkAlert("Error", this.sub_err_text);
    }
  }

  uploadSequenceKeyPress(event: KeyboardEvent){
    if(event.keyCode == 27){
      if(this.upload_process_page <= 1){
        this.closeUploadProcess();
      }
    }
  }

  proceedPayment(){
    // if(!this.sub_err){
    //   this.showFSLoader("Processing payment...");
    //   this.network.sendRequest({did: this.submission.department, corrNo: this.correspondence_no}, NetworkService.FS_PAYMENT).subscribe((res)=>{
    //     if(!res['error']){
    //       var data = JSON.parse(res["response"]);
    //       var key = data["key"];
    //       var ref = data["ref"];
    //       var amount = data["amount"];
    //       var meta = [{metaname: "dept_id", metavalue: this.submission.department}]
    //       this.payWithPayStack(key,ref,amount, (rf) => {this.FSPaymentSuccess(rf)}, () => {this.FSPaymentFailed()}, meta);
    //     }else{
    //       this.alertHelper.showOkAlert("Error", res['response']);
    //     }
    //   }, (error)=>{
    //     this.alertHelper.showOkAlert("Failed", "An unexpected error occurred when trying to make payment");
    //   })
    // }
  }

  payWithPayStack(key, ref, amount, success: (ref) => any, failed: () => any, meta?: any) {
    var __this = this;
    // var handler = this.winRef.nativeWindow.PaystackPop.setup({
    //   key: key,
    //   email: this.loggedUser.email,
    //   amount: amount,
    //   currency: "NGN",
    //   ref: ref,
    //   metadata: {
    //      custom_fields: []
    //   },
    //   callback: function(response: PayStackResponse){
    //     //alert('success. transaction ref is ' + response.reference);
    //     if(response.status == 'success'){
    //       success(ref);
    //     }else{
    //       failed();
    //     }
    // },
    // onClose: function(){
    //     failed();
    // }
    // });
    // handler.openIframe();

  }

  private FSPaymentSuccess(rf){
    this.dismissFSLoader();
    // this.network.sendRequest({reference: rf}, NetworkService.VERIFY_FS_PAYMENT).subscribe((res) => {
    //   if(!res["error"]){
    //     this.reloadDashBoardVars();
    //   }else{
    //     this.alertHelper.showOkAlert("Error", "Could not verify payment");
    //   }
    // }, (error) => {
    // })
    // this.payment_done = true;
    // //this.alertHelper.showOkAlert("Success", "Payment was made successfully");
    // this.cd.detectChanges();
  }
  private FSPaymentFailed(){
    this.dismissFSLoader();
    //this.alertHelper.showOkAlert("Failed", "An error occured with your payment!");
    this.cd.detectChanges();
  }

  showFSLoader(text: string = "Processing..."){
    this.submit_loader = true;
    this.submit_loader_text = text;
  }

  dismissFSLoader(){
    this.submit_loader = false;
    this.submit_loader_text = "";
  }
  fileInfo = [];
  trackingNo = '';

  sendToPrint(value: string) {
    var doc = window.open(value);
    doc.print();
  }

  handleSubmissionProgress(event){
    if (event.type === HttpEventType.Response) {
      this.dismissFSLoader();
      var serverResponse = event.body
      if(!serverResponse['error']){
        var response = JSON.parse(serverResponse['response']);
        this.trackingNo = response['trackingRef'];
        this.fileInfo = response['paths'];
        this.correspondence_no = this.trackingNo;
        this.upload_process_page = 2;
      }else{
        this.sub_err = true;
        this.sub_err_text = serverResponse['response'];
        this.alertHelper.showOkAlert("Error", this.sub_err_text);
      }
    }
  }


  //Methods copied ends here //

  // Track file related functions starts here
  track_details = {ref_no: "#889d9SW",subject: "A Subject", status: 0, notifications: [ { stage: "Acknowlegdement", n_date: "2019/2/19", in_charge: 'Olu Jacobs', status: "Completed" } ] }
  track_file_show = false;

  track_ref_no: string = "";
  aysn_track_file: boolean = false;
  track_err: boolean = false;
  track_err_text: string = "";
  dash_track_err: boolean = false;
  dash_track_err_text: string = "";
  dash_track_ref: string = "";
  track_ref: string = "";
  closeTrackFile(){
    this.track_file_shadow = false;
    this.track_file_show = false;
  }
  getFullDate(dateString){
    return moment(dateString).format("MMMM Do YYYY, h:mm a")
  }
  modalTrackFile(){
    this.track_err = false;
    if(this.track_ref.trim() != ""){
      if(this.track_ref.trim().charAt(0) == '#' && this.track_ref.trim().length > 3){
        this.trackFile(this.track_ref);
      }else{
        this.track_err = true;
        this.track_err_text = "Invalid correspondence number."
      }
    }else{
      this.track_err = true;
      this.track_err_text = "The correspondence number is required."
    }
  }

  modalTrackFileInput(){
    if(this.track_ref.trim() != ""){
      if(this.track_ref.trim().charAt(0) != '#'){
        this.track_ref = '#' + this.track_ref;
      }else{
        var re = /(#)+/;
        this.track_ref = this.track_ref.replace(re, '#');
        this.cd.detectChanges();
      }
    }
  }

  modalTrackFileKeyPress(event: KeyboardEvent){
    if(event.keyCode == 13){
      this.modalTrackFile();
    }else{
      if((event.key == '#' || event.keyCode == 163) && this.track_ref.length > 0){
        if(this.track_ref.charAt(this.track_ref.length - 1) == '#'){
          event.preventDefault();
        }
      }
    }
  }

  //Track Files
  trackFile(ref?: string){
    this.show_sidebar = false;
    this.track_err = false;
    this.track_file_shadow = true;
    this.track_file_show = true;
    console.log('here now');
    if(ref){
      this.aysn_track_file = true;
      this.track_details = null;
      this.network.sendRequestG2G({trackingRef: ref}, NetworkService.TRACK_FILE_G2G).subscribe((res)=>{
        if(!res['error']){
          this.track_details = JSON.parse(res['response']);
          console.log(this.track_details);
        }else{
          this.track_err = true;
          this.track_err_text = res['response'];
        }
        this.aysn_track_file = false
      }, (error) =>{
        this.aysn_track_file = false;
        this.track_err = true;
        this.track_err_text = "Sorry an unexpected error occured";
      })
    }
  }


  dashTrackFile(){
    this.dash_track_err = false;
    var err = this.dashTrackFileParent(this.dash_track_ref);
    if(typeof err == 'string'){
      this.dash_track_err = true;
      this.dash_track_err_text = err;
    }
  }


  dashTrackFileParent(dash_ref) : void | string{
    this.dash_track_ref = dash_ref;

    this.dash_track_err = false;
    if(this.dash_track_ref.trim() != ""){
      if(this.dash_track_ref.trim().charAt(0) == '#' && this.dash_track_ref.trim().length > 3){
        this.trackFile(this.dash_track_ref);
      }else{
        return "Invalid correspondence number.";
      }
    }else{
      return "The correspondence number is required.";
    }
  }

  trackFileInput(){
    if(this.dash_track_ref.trim() != ""){
      if(this.dash_track_ref.trim().charAt(0) != '#'){
        this.dash_track_ref = '#' + this.dash_track_ref;
      }else{
        var re = /(#)+/;
        this.dash_track_ref = this.dash_track_ref.replace(re, '#');
      }
    }
  }

  trackFileKeyPress(event: KeyboardEvent){
    if(event.keyCode == 13){
      this.dashTrackFile();
    }else{
      if((event.key == '#' || event.keyCode == 163) && this.dash_track_ref.length > 0){
        if(this.dash_track_ref.charAt(this.dash_track_ref.length - 1) == '#'){
          event.preventDefault();
        }
      }
    }
  }


  // All archive to send related functions starts here
  openArchModal = false;
  fetchingAllArchive = false;
  public fetchedDeptArchives: {[key: string]: Object }[];
  // map the groupBy field with category column
  public fields: Object = { groupBy: 'FileRefNo', text: 'FileName', value: 'FilePath' };
  // Set the popup list height
  public height: string = '200px';
  // set the placeholder to the MultiSelect input
  public placeholder: string = 'Select files from any submission ';
  // set value of enableGroupCheckBox to true
  enableGroupCheckBox: boolean = true;
  // set value of allowFiltering to true
  allowFiltering: boolean = true;
  // set the placeholder to the filterbar
  filterBarPlaceholder: string = "Search by full name";
  // set the value of showSelectAll as true
  showSelectAll: boolean = true;

  maximumSelectionLength = 3;

  selectedFilesFromArchive: any = [];
  fetchError: boolean = false;
  openArchiveModal() {
    this.openArchModal = true;
    this.maximumSelectionLength = 5 - (this.selectedFiles.length + this.selectedDummyFiles.length);
    if (this.maximumSelectionLength <= 0) {
      this.selectionError = true;
      this.selectionErrorText = 'You can no longer select a file from here, maximum number of files reached!';
    } else {
      this.selectionError = false;
      this.fetchAllArchives();
    }
  }

  fetchAllArchives() {
    this.fetchingAllArchive = true;
    this.network.sendRequestG2G({}, NetworkService.FETCH_ARCHIVE_FILES_DETAILS).subscribe((result)=> {
      if(!result['error']) {
        this.fetchError = false;
        this.fetchingAllArchive = false;
        this.fetchedDeptArchives = JSON.parse(result['response']);
        console.log(this.fetchedDeptArchives);
      } else {
        this.fetchError = true;
        this.fetchingAllArchive = false;
      }
    }, (error)=> {
      this.fetchError = true;
      this.fetchingAllArchive = false;
    });
    // setTimeout(()=> {
    //   this.fetchingAllArchive = false;
    // }, 5000);
  }

  selectionErrorText = '';
  selectionError = false;
  selectedFilesPathAndNames = Array({});

  attachSelectedFiles(){
    if(this.upload_process == false) {
      this.upload_process = true;
    }
    if (this.selectedFilesFromArchive.length == 0) {
      this.selectionErrorText = 'No file selected, click on close if you no longer want to attach a file from here';
      this.selectionError = true;
    } else {
      // console.log(this.selectedFilesFromArchive);
      // console.log(this.selectedFilesPathAndNames);
      this.selectedFilesPathAndNames = [];
      this.selectedFilesFromArchive.forEach(element => {
        var findFile = this.fetchedDeptArchives.find((f)=>{
          return f.FilePath == element;
        });
        var check = this.selectedDummyFiles.find((f)=> {
          return f.name == findFile.FileName;
        });
        if(!check) {
          this.selectedFilesPathAndNames.push({fileName: findFile.FileName.toString(), filePath: findFile.FilePath.toString()});
          var splitted = element.split('.', 2);
          var fileType = splitted[1];
          const downloadedFile = new Blob([element], { type: 'application/'+fileType } );
          var file = new File([downloadedFile], findFile.FileName.toString(), {type: 'application/'+fileType, lastModified: Date.now()});
          this.selectedDummyFiles.push(file);
          this.setFileSizes();
          this.openArchModal = false;
        } else {
            this.selectionErrorText = 'One or more files selected has previously been attached, please check your selection';
            this.selectionError = true;
        }
      });
      // console.log(this.selectedFilesPathAndNames);
      // this.selectedFilesFromArchive.forEach(element => {
      //   var splitted = element.split('.', 2);
      //   var fileType = splitted[1];
      //   const downloadedFile = new Blob([element], { type: 'application/'+fileType } );
      //   var file = new File([downloadedFile], 'filename.'+fileType, {type: fileType, lastModified: Date.now()});
      //   this.selectedFiles.push(file);

      // });
      // var splitted = this.selectedFilesFromArchive[0].split('.', 2);
      // var fileType = splitted[1];
      // const downloadedFile = new Blob([this.selectedFilesFromArchive[0]], { type: 'application/'+fileType } );
      // var file = new File([downloadedFile], 'filename.'+fileType, {type: fileType, lastModified: Date.now()});
      // // this.selectedFiles.push(file);
      // console.log(downloadedFile);
      // var rezs = this.loadFile(this.selectedFilesFromArchive[0]);
      // console.log(rezs);
      // // downloadedFile.lastModifiedDate = new Date()
      // console.log(downloadedFile);
      // this.selectionError = false;
    }
  }


  closeArchiveModal(){
    this.openArchModal = false;
  }


// Scan module functions starts here



scanDocument(){
  this.winRef.nativeWindow.scanDocument();
}

@HostListener('window:scandone', ['$event'])
listenForScan(event: CustomEvent){
  //console.log(event)
  var successful = event.detail.successful;
  var mesg = event.detail.mesg;
  var response = event.detail.response;
  if(!successful) { // On error
    //console.error('Failed: ' + mesg);
    this.alertHelper.showOkAlert("Error", mesg);
    return;
  }

  if(successful && mesg != null && mesg.toLowerCase().indexOf('user cancel') >= 0) { // User canceled.
      //console.info('User canceled');
      return;
  }

  var scannedImages = this.winRef.nativeWindow.scanner.getScannedImages(response, true, false); // returns an array of ScannedImage
  for(var i = 0; (scannedImages instanceof Array) && i < scannedImages.length; i++) {
      var scannedImage = scannedImages[i];
      this.processScannedImage(scannedImage);
  }
}

processScannedImage(scannedImage) {
  var __this = this;
  var re = /(-)+/gi;
  Utils.urltoFile(scannedImage.src, 'Scan_' + Utils.createGUID().replace(re, '') + '.pdf', scannedImage.mimeType)
      .then(function(file){
          //console.log(file);
          __this.selectedFiles.push(file);
          var total_file_count = this.selectedFiles.length;
          this.totalFIlesAdded = total_file_count;
          // __this.showUploadInterface = true;
          // __this.hideViewInterface = true;
          this.upload_process = true;
          var fileError: boolean = false;
          if(total_file_count <= this.max_number_of_files){
              var findFile = this.selectedFiles.find((f)=>{
                return f.name == file.name;
              });
              if(!findFile){
                if(this.file_types_url[file.type]){
                  // this.selectedFiles.push(mock_files.files[i]);
                  var file_size = +((file.size/1024)/1024);
                  this.totFileSize += file_size;
                  this.totFileSizeString = this.totFileSize.toFixed(2) + 'MB';
                }else{
                  this.totalFIlesAdded = this.totalFIlesAdded - 1;
                  fileError = true;
                }
              }else{
                this.totalFIlesAdded = this.totalFIlesAdded - 1;
                fileError = true;
              }
            if(fileError){
              this.alertHelper.showOkAlert('Skipped files', 'Some selected files have been skipped because they have either been uploaded already or they are not of a supported file format')
            }
          }else{
            this.alertHelper.showOkAlert('Skipped files', 'You have reached the maximum number of files')
          }
      })
}



// Scan module functions ends here






  // All Gov't MDA Statistic related functions starts here


}



export class DashboardValues{
  uploaded_files = {count: 0, today: 0};
  treated_files = {count: 0, today: 0};
  pending_files = {count: 0, today: 0};
  notifications: Array<NotificationItem> = new Array();
  notificationCount: number = 0;
  max_number_of_files: number = 5;
  max_files_size: number = 5;
  max_recepients: number = 5;
}


export class NotificationItem{
  id: number;
  status: number;
  title: string = "";
  message: string = "";
  time: string = "";
  extra: string;
}
