import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { NetworkService } from "./../network/_services/network";
import { User } from "src/app/models/user";
import { DashboardLayoutComponent } from "./../dashboard-layout/dashboard-layout.component";
import { requiredFileTypes } from "src/app/shared/file-upload-validator";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AlertHelper } from "../helpers/alert-helper";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("closeDrawer", { static: false }) closeDrawer: ElementRef<
    HTMLElement
  >;

  parent: DashboardLayoutComponent;
  loggedUser: User;

  frm_err: boolean = false;
  frm_err_text: string = "";
  show_profile_async: boolean = false;
  alertHelper: AlertHelper;
  fetch_details_async: boolean = true;

  // Signature Related Code
  signatureForm: FormGroup;
  sub: Subscription = new Subscription();
  signatureLoad: Boolean = false;

  // Image Cropper Related
  imageChangedEvent: any = "";
  croppedImage: any = "";
  backColor = "#000000";
  croppedImageFile: any = null;

  usr_data = {
    Email: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    PhoneNo: "",
    PhoneNo2: "",
    PersonalEmail: "",
    TitleId: null,
    StaffId: null,
    EmailSignatureUrl: null,
  };

  constructor(
    private network: NetworkService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.loggedUser = User.getCurrentUser();
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    // this.initSignatureForm();
    this.network.sendRequest({}, NetworkService.FETCH_DETAILS).subscribe(
      (res) => {
        this.fetch_details_async = false;
        if (!res["error"]) {
          this.usr_data = JSON.parse(res["response"]);
          console.log(this.usr_data);
        } else {
        }
      },
      (error) => {
        this.fetch_details_async = false;
        this.alertHelper.showErrorAlert();
      }
    );
  }
  goHome() {
    this.router.navigate(["/"]);
  }

  saveProfile() {
    this.frm_err = false;
    var errorInProfile = this.validateProfile();
    if (typeof errorInProfile == "string") {
      this.frm_err = true;
      this.frm_err_text = errorInProfile;
      this.alertHelper.showOkAlert("Error", this.frm_err_text);
    } else {
      //Submit Form
      console.log(this.usr_data);
      this.show_profile_async = true;
      this.network
        .sendRequest(this.usr_data, NetworkService.UPDATE_PROFILE)
        .subscribe(
          (result) => {
            this.show_profile_async = false;
            if (!result["error"]) {
              var user: User = JSON.parse(result["response"]);
              User.updateCurrentUser(user, true);
              this.alertHelper.showOkAlert(
                "Success",
                "Profile Updated Successfully",
                () => {
                  this.router.navigate(["/dashboard"]);
                },
                4000
              );
            } else {
              this.alertHelper.showOkAlert(
                "Profile Update Error",
                result["response"]
              );
            }
          },
          (error) => {
            this.show_profile_async = false;
            this.alertHelper.showErrorAlert();
          }
        );
    }
  }

  validateProfile(): string | boolean {
    if (this.usr_data.FirstName.trim() == "") {
      return "Please enter your first name";
    } else if (this.usr_data.MiddleName.trim() == "") {
      return "Please enter your middle name";
    } else if (this.usr_data.LastName.trim() == "") {
      return "Please enter your last name";
    } else if (this.usr_data.PhoneNo.trim() == "") {
      return "Please enter your mobile number";
    } else if (this.usr_data.PersonalEmail.trim() == "") {
      return "Please enter personal email";
    } else if (this.usr_data.StaffId == null) {
      return "Please select your staff ID";
    } else if (this.usr_data.TitleId.trim() == "") {
      return "Please enter your title";
    } else if (this.usr_data.Email.trim() == "") {
      return "Please enter your email";
    }
    return false;
  }

  // // All signature related codes
  // initSignatureForm() {
  //   this.signatureForm = this.fb.group({
  //     Attachment: [null, [Validators.required]],
  //   });
  // }

  // addSignature() {
  //   if (this.croppedImageFile != null) {
  //     this.signatureLoad = true;
  //     console.log(this.signatureForm.get("Attachment").value);
  //     this.sub.add(
  //       this.network
  //         .signatureUploadNoKey(
  //           [this.croppedImageFile],
  //           "int/upload-signature",
  //           {}
  //         )
  //         .subscribe({
  //           next: (res: any) => {
  //             this.signatureLoad = false;
  //             this.usr_data.EmailSignatureUrl = res["EmailSignatureUrl"];
  //             this.closeDrawer.nativeElement.click();
  //           },
  //           error: (e) => {
  //             this.signatureLoad = false;
  //             this.alertHelper.showErrorAlert();
  //             console.log(e);
  //           },
  //         })
  //     );
  //   } else {
  //     this.alertHelper.showOkAlert("Error", "Please select a file!");
  //   }
  // }

  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }
  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  //   this.croppedImageFile = this.dataURLtoFile(
  //     this.croppedImage,
  //     "signatureImage.png"
  //   );
  // }
  // imageLoaded() {
  //   console.log(this.croppedImageFile);
  //   this.croppedImageFile = this.imageChangedEvent.target.files[0];
  //   console.log(this.croppedImageFile);
  // }
  // cropperReady() {
  //   // cropper ready
  // }
  // loadImageFailed() {
  //   // show message
  // }
  // dataURLtoFile(dataurl, filename) {
  //   const arr = dataurl.split(",");
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }
}
