import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'docstream-int';
  currentPage: any;
  hide_scroll: boolean = false;


  eventCalled(event) {
    this.currentPage = event;
    event.parent = this;
  }

  hideScroll(){
    this.hide_scroll = true;
  }
  showScroll(){
    this.hide_scroll = false;
  }
}


