export class SREvent{
    public static RECEIVE_FS_NOTIFICATION = "IntReceiveFSNotification";
    public static RECEIVE_CHAT_CREATED = "ChatCreated";
    public static RECEIVE_CHAT_CREATE_ERROR = "ChatCreateError";
    public static RECEIVE_CHAT_MESSAGE = "ReceiveMessage";
    public static RECEIVE_GROUP_CHAT_MESSAGE = "ReceiveGroupChatMessage";
    public static RECEIVE_GROUP_ADD = "ReceiveGroupAdd";
    public static RECEIVE_GROUP_ADD_USER = "ReceiveGroupAddUser";
    public static RECEIVE_GROUP_REMOVE_USER = "ReceiveGroupRemoveUser";
    public static RECEIVE_GROUP_USER_EXIT = "ReceiveGroupUserExit";
    public static RECEIVE_GROUP_NAME_CHANGE = "ReceiveGroupNameChange";
    public static RECEIVE_GROUP_TOGGLE_ADMIN = "ReceiveGroupToggleAdmin";
    public static SEND_CHAT = "SendMessageToUser";
    public static SEND_GROUP_CHAT = "SendGroupMessage";
    public static CLEAR_UNREAD = "ClearUnread";
    public static DASHBOARD_REFRESH = "DashRefresh";
    public static REAL_DASHBOARD_REFRESH = "DashRefresh";
    public static FSCOMMENT_REFRESH = "FSCommentsRefresh";
    public static TREAT_FILE_REFRESH = "TreatFileRefresh";
    public static MEMO_REFRESH = "MemoRefresh";
    public static MEMO_COMMENT_REFRESH = "MemoCommentsRefresh";
}