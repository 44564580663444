import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../network/_services/network';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertHelper } from '../helpers/alert-helper';
import { User } from '../models/user';
// import moment = require('moment');
import moment from "moment";

@Component({
  selector: 'app-view-leave',
  templateUrl: './view-leave.component.html',
  styleUrls: ['./view-leave.component.css']
})
export class ViewLeaveComponent implements OnInit {
  errorPage: boolean;
  showRetryOnError: boolean;
  errorPageText = 'Sorry an unexpected error occured';
  leaveDetailsLoader: boolean;
  alertHelper: AlertHelper;
  leave_ref: string;
  LeaveInfo = new LeaveInfo();
  loggedUser: any;
  username: any;
  LeaveData: any;
  showLMComment = false;
  showHHRComment = false;
  showHODComment = false;
  showLMAsync = false;
  rejectResponse: string;
  submit_loader = false;
  submit_loader_text: string;
  rejectionComment = '';
  isInitiator: any;
  isHOD = false;

  constructor( private network:NetworkService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {
    this.route.params.subscribe((params) => {
      // console.log(params)
      this.leave_ref = params['LeaveRefNumber'];
    })
    if(!this.leave_ref) {
      this.router.navigate(['/']);
    }else{
      this.LeaveInfo.ref = '#' + this.leave_ref;
      this.leave_ref = this.LeaveInfo.ref;
    }
    this.alertHelper = new AlertHelper(dialog);
    this.loggedUser = User.getCurrentUser();
    this.username = this.loggedUser.FirstName + " " + this.loggedUser.LastName;
   }

  ngOnInit() {
    if(localStorage.getItem('isHOD') == 'true') {
      this.isHOD = true;
    } else {
      this.isHOD = false;
    }
    this.loadLeaveDetails();
  }
  showFSLoader(text: string = "Processing..."){
    this.submit_loader = true;
    this.submit_loader_text = text;
  }

  dismissFSLoader(){
    this.submit_loader = false;
    this.submit_loader_text = "";
  }

  showLineManagerLeaveCommentBox() {
    this.showLMComment = true;
  }

  closeLineManagerLeaveCommentBox() {
    this.showLMComment = false;
  }

  showHodLeaveCommentBox() {
    this.showHODComment = true;
  }

  closeHodLeaveCommentBox() {
    this.showHODComment = false;
  }

  showHhrLeaveCommentBox() {
    this.showHHRComment = true;
  }

  closeHhrLeaveCommentBox() {
    this.showHHRComment = false;
  }

  submitRejectMessage() {
    if( this.rejectionComment === '') {
      this.alertHelper.showOkAlert('Error', 'You need to enter the reason for rejection');
    } else {
      this.disapproveLeave(this.rejectionComment);
    }
  }
  // leaveRefToSend: any;
  loadLeaveDetails() {
    this.leaveDetailsLoader = true;
    // var leaveRefToSend = this.leave_ref;
    this.network.sendRequest({leaveRef: this.LeaveInfo.ref}, NetworkService.FETCH_LEAVE_DETAIL).subscribe((response) => {
      this.leaveDetailsLoader = false;
      if (!response['error']) {
        var result = JSON.parse(response['response']);
        console.log(result);
        this.LeaveData = result[0];
      }
    });
  }

  formatDate(date) {
    return moment(date).format('DD-MM-YYYY');
  }

  getApprovalType(value) {
    if (value == 1) {
      return 'Pending';
    } else if (value == 2) {
      return 'Approved';
    } else {
      return 'Disapproved';
    }
  }

  approveLeave() {
    // tslint:disable-next-line: max-line-length
    var data = {RefNo: this.LeaveInfo.ref, Response: 'Leave Approved', Approval: true};
    this.alertHelper.showYesNoAlert('Warning', 'Are you sure you want to approve this leave request?', ()=>{
      this.showFSLoader('Submitting...');
      this.network.sendRequest(data, NetworkService.APPROVE_LEAVE_REQUEST).subscribe((response) => {
        this.leaveDetailsLoader = false;
        if (!response['error']) {
          this.dismissFSLoader();
          this.alertHelper.showOkAlert('Success', 'Leave application approved!', ()=> {
            this.loadLeaveDetails();
          }, 5000);
        } else {
          this.dismissFSLoader();
          this.alertHelper.showOkAlert('Error', response['response']);
        }
      }, (error)=> {
        this.dismissFSLoader();
        this.alertHelper.showErrorAlert();
      });
    })

  }


  disapproveLeave(rejectComment) {
      // tslint:disable-next-line: max-line-length
      var data = {RefNo: this.LeaveInfo.ref, Response: rejectComment, Approval: false};
      this.alertHelper.showYesNoAlert('Warning', 'Are you sure you want to reject this leave request?', ()=>{
        this.showFSLoader('Submitting...');
        this.network.sendRequest(data, NetworkService.APPROVE_LEAVE_REQUEST).subscribe((response) => {
          this.leaveDetailsLoader = false;
          if (!response['error']) {
            this.dismissFSLoader();
            this.showLMComment = false;
            this.showHODComment = false;
            this.showHHRComment = false;
            this.rejectionComment = '';
            this.alertHelper.showOkAlert('Success', 'Leave application rejected!', ()=> {
              this.loadLeaveDetails();
            }, 5000);
          } else {
            this.dismissFSLoader();
            this.alertHelper.showOkAlert('Error', response['response']);
          }
        }, (error) => {
          this.dismissFSLoader();
          this.alertHelper.showErrorAlert();
        });
      });

    }


  printLeave()
  {
    html2canvas(document.querySelector("#leave-details-container")).then(canvas => {

      var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

      var imgData  = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData,0,0,canvas.width, canvas.height);
      pdf.save('');
    });
  }
}

class LeaveInfo {
  ref = '';
  Sender = ''; // name, position
  UploadedBy = '';
  DateOnDocument = '';
  UploadedOn = '';
  Title = '';
  AddressedTo = '';
  Recipients: Array<{email: string, Department: string, Position: string}> = new Array();
  Department = '';
  DocumentType = '';
  files: Array<{fid: string, file_name: string, url: string}> = new Array();
  level = '';
  // comments: Array<FileComment> = new Array();
  isFinalizableByUser: boolean;
  isFinalizableByAnotherUser: boolean;
  isRoutable: boolean;
  }

