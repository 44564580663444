import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormBase } from "src/app/models/DynamicFormBase";

@Component({
  selector: "app-dynamic-form-builder",
  templateUrl: "./dynamic-form-builder.component.html",
  styleUrls: ["./dynamic-form-builder.component.scss"],
})
export class DynamicFormBuilderComponent {
  @Input() formBase: DynamicFormBase<string>;
  @Input() form: FormGroup;
  get isValid() {
    return this.form.controls[this.formBase.key].valid;
  }
}
