import { Observable, throwError, Subscription } from 'rxjs';
import { HttpEventType, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Utils } from './../helpers/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material';
import { AlertHelper } from './../helpers/alert-helper';
import { Helpers } from './../helpers';
import { NetworkService } from './../network/_services/network';
import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, NgModule } from '@angular/core';
import moment from "moment";
import * as $ from "jquery";
import { HubConnection } from '@aspnet/signalr';
import { SREvent } from '../helpers/signalr-events';
import * as mime from 'mime';
import { map, catchError } from 'rxjs/operators';
import { EmojiModule, EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { saveAs } from 'file-saver';
import { CacheRequest } from '../models/cache';

@Component({
  selector: 'app-chatpage',
  templateUrl: './chatpage.component.html',
  styleUrls: ['./chatpage.component.scss']
})
export class ChatpageComponent implements OnInit, AfterViewChecked {

  @ViewChild('bubbles', { static: false }) private bubbles: ElementRef;
  @ViewChild('cinput', { static: false }) private cinput: ElementRef;
  @ViewChild('attachFileInp', { static: false }) private attachFileInp: ElementRef;
  @ViewChild('pImg', { static: false }) private pImg: ElementRef;
  @ViewChild('nails', { static: false }) private nails: ElementRef;
  main_chats: Array<Chat> = new Array();
  chats: Array<Chat> = new Array();
  selectedChat: Chat;
  categorizedMessages: Array<{ category: string, messages: Array<ChatMessage> }> = new Array();

  show_cl: boolean = false;
  cl_loader: boolean = true
  chat_messages_loader: boolean = false;

  searchValue: string = "";

  add_user: boolean = false;
  add_user_async: boolean = false;

  create_group_async: boolean = false;
  add_group_member: boolean = false;
  add_group_member_async: boolean = false;

  institutions_load_async: boolean = false;
  institutions_users_load_async: boolean = false;
  departments_load_async: boolean = false;
  department_users_load_async: boolean = false;

  institutions: { id: string, name: string, users: { id: string, name: string }[] }[] = new Array();
  departments: { id: string, name: string, users: { id: string, name: string }[] }[] = new Array();
  institution_users: { id: string, name: string }[] = new Array();
  department_users: { id: string, name: string }[] = new Array();
  toggle_users_dropdown: boolean = true;
  selected_chat_user: { id: string, name: string, fname: string, lname: string, email: string, color: string, position: string };
  sel_institution: { id: string, name: string, users: { id: string, name: string }[] };
  sel_department: { id: string, name: string, users: { id: string, name: string }[] };
  selected_group_users: GroupMember[] = new Array();
  current_selected_group_users: { id: string, name: string, fname: string, lname: string, email: string, color: string, position: string }[] = new Array();

  institution_data: { id: string, name: string, departments: { id: string, iid: string, name: string, users: { id: string, name: string }[] }[] }[] = new Array();

  newGroupName: string = '';

  config = Helpers.DEFAULT_NGX_SELECT_DROPDOWN_CONFIG;
  alertHelper: AlertHelper;

  private hubConnection: HubConnection;

  current_add_chat_type_tab: number = 0;

  loggedUser: User;

  chat_info: boolean = false;
  isSelectedChatGroupAdmin: boolean = false;

  member_list_async_action = [];

  preview_attachment: boolean = false;

  open_smiley: boolean = false;
  previewImage: boolean = false;
  selectedPreviewImage: FileAttachment;

  groupNameEditMode: boolean = false;
  groupNameEditAsync: boolean = false;
  selectedChatGroupName: string = "";


  constructor(private network: NetworkService, private dialog: MatDialog, private sanitizer: DomSanitizer) {
    this.alertHelper = new AlertHelper(dialog);
    this.loggedUser = User.getCurrentUser();
  }

  onMyChatCreated(data) {
    var details: { code: string, uid: string } = JSON.parse(data);
    this.main_chats.every((c) => {
      if (c.with.id == details.uid) {
        c.code = details.code;
        if (this.searchValue.trim() == "") {
          this.chats = this.main_chats;
        }
        return false;
      }
    })
  }

  onReceiveChatMessage(data) {
    var details: { code: string, uid: string, message: string, time: string, email: string, fname: string, lname: string, color: string, previewLink: string, previewName: string } = JSON.parse(data);
    var message = new ChatMessage();
    message.message = details.message;
    message.sent = false;
    message.time = details.time;
    message.attachedFile = new FileAttachment();
    message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Uploaded;
    message.attachedFile.name = details.previewName;
    message.attachedFile.previewLink = details.previewLink;
    if(details.previewLink){
      message.attachedFile.type = this.getFileTypeFromMimeType(mime.getType(details.previewLink));
    }
    var entered = false;
    var currentChat = false;
    this.main_chats.forEach((c) => {
      if(c.with.type == ChatWithType.Private && c.with.id != null){
        if (c.with.id.toLowerCase() == details.uid.toLowerCase()) {
          c.lastmsg = message;
          if (this.selectedChat) {
            if (c.with.id.toLowerCase() != this.selectedChat.with.id) {
              c.unread += 1;
              if(c.messages){
                c.messages.push(message);
              }
            }else{
              currentChat = true;
              if(!c.messages){
                c.messages = []
              }
              c.messages.push(message);
            }
          }else{
            c.unread += 1;
          }
          if (this.searchValue.trim() == "") {
            this.chats = this.main_chats;
          }
          entered = true
          return;
        }
      }
    })
    if (!entered) {
      var chat = new Chat();
      var cWith = new ChatWith();
      cWith.fname = details.fname;
      cWith.lname = details.lname;
      cWith.color = details.color;
      cWith.email = details.email;
      cWith.id = details.uid;
      chat.with = cWith;
      chat.currentInput = "";
      chat.unread = 1;
      chat.messages.push(message);
      chat.lastmsg = message;
      this.main_chats.unshift(chat);
      if (this.searchValue.trim() == "") {
        this.chats = this.main_chats;
      }
    }
    if(currentChat){
      this.categorizeChatMessages(this.selectedChat.messages, false, true);
      this.hubConnection.invoke(SREvent.CLEAR_UNREAD, this.selectedChat.code, this.selectedChat.with.type);
      setTimeout(() => {
        this.selectedChat.unread = 0;
      }, 700);
    }

  }

  pushNewGroupMessageToChat(c: Chat, message: ChatMessage){
    var currentChat = false
    c.lastmsg = message;
    if (this.selectedChat) {
      if (c.code.toLowerCase() != this.selectedChat.code.toLowerCase()) {
        c.unread += 1;
        if(c.messages){
          c.messages.push(message);
        }
      } else {
        currentChat = true;
        if(!c.messages){
          c.messages = [];
        }
        c.messages.push(message);
      }
    }else{
      c.unread += 1;
    }
    if (this.searchValue.trim() == "") {
      this.chats = this.main_chats;
    }
    return currentChat;
  }

  onReceiveGroupChatMessage(data) {
    var details: { code: string, uid: string, message: string, time: string, byUsr: string, previewLink: string, previewName: string } = JSON.parse(data);
    var message = new ChatMessage();
    message.message = details.message;
    message.sent = false;
    message.time = details.time;
    message.byUsr = details.byUsr;
    message.attachedFile = new FileAttachment();
    message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Uploaded;
    message.attachedFile.name = details.previewName;
    message.attachedFile.previewLink = details.previewLink;
    if(details.previewLink){
      message.attachedFile.type = this.getFileTypeFromMimeType(mime.getType(details.previewLink));
    }
    var currentChat = false;
    this.main_chats.forEach((c) => {
      if (c.code.toLowerCase() == details.code.toLowerCase()) {
        currentChat = this.pushNewGroupMessageToChat(c, message);
        return;
      }
    })
    if (currentChat) {
      this.categorizeChatMessages(this.selectedChat.messages, false, true);
      this.hubConnection.invoke(SREvent.CLEAR_UNREAD, this.selectedChat.code, this.selectedChat.with.type);
      setTimeout(() => {
        this.selectedChat.unread = 0;
      }, 700);
    }
  }

  onReceiveGroupAdd(data) {
    var details: { code: string, with: { type: number, name: string, members: any[] } } = JSON.parse(data);
    var chat = new Chat();
    var cWith = new ChatWith();
    cWith.type = ChatWithType.Group;
    cWith.members = details.with.members;
    cWith.name = details.with.name;

    chat.with = cWith;
    chat.currentInput = "";
    chat.unread = 0;
    chat.code = details.code;

    this.main_chats.unshift(chat);
  }

  onReceiveGroupNameChange(data){
    var details: { code: string, name: string, message: string, time: string} = JSON.parse(data);
    var message = new ChatMessage();
    message.message = details.message;
    message.sent = false;
    message.time = details.time;
    var currentChat = false;
    this.main_chats.every((c) => {
      if(c.code.toLowerCase() == details.code.toLowerCase()){
        if(c.with.type == ChatWithType.Group){
          c.with.name = details.name;
        }
        currentChat = this.pushNewGroupMessageToChat(c, message);
        return false;
      }
    })
    if (currentChat) {
      this.categorizeChatMessages(this.selectedChat.messages, false, true);
    }
  }

  onReceiveGroupRemoveUser(data){
    var details: { code: string, uid: string, message: string, time: string } = JSON.parse(data);
    var message = new ChatMessage();
    message.message = details.message;
    message.sent = false;
    message.time = details.time;
    message.action = true;
    var currentChat = false;
    var removedSelf: boolean = false;
    this.main_chats.every((c) => {
      if(c.code.toLowerCase() == details.code.toLowerCase()){
        c.with.members = c.with.members.filter((m) => {
          removedSelf = m.id == details.uid && m.self;
          return m.id != details.uid;
        })
        if(removedSelf){
          this.alertHelper.showOkAlert("Removed", "You have been removed from "+ c.with.name);
          return false;
        }
        currentChat = this.pushNewGroupMessageToChat(c, message);
        return false;
      }
    })
    if (currentChat) {
      this.categorizeChatMessages(this.selectedChat.messages, false, true);
    }
    if(removedSelf){
      this.main_chats = this.main_chats.filter((c) => {
        return c.code != details.code;
      })
    }
  }

  onReceiveGroupUserExit(data){
    var details: { code: string, uid: string, message: string, time: string } = JSON.parse(data);
    var message = new ChatMessage();
    message.message = details.message;
    message.sent = false;
    message.time = details.time;
    message.action = true;
    var currentChat = false;
    this.main_chats.every((c) => {
      if(c.code.toLowerCase() == details.code.toLowerCase()){
        c.with.members = c.with.members.filter((m) => {
          return m.id != details.uid;
        })
        currentChat = this.pushNewGroupMessageToChat(c, message);
        return false;
      }
    })
    if (currentChat) {
      this.categorizeChatMessages(this.selectedChat.messages, false, true);
    }
  }

  onReceiveGroupAddUser(data){
    var details: { code: string, data: GroupMember[], time: string} = JSON.parse(data);
    var currentChat = false;
    this.main_chats.every((c) => {
      if(c.code.toLowerCase() == details.code.toLowerCase()){
        details.data.forEach((m) => {
          var message = new ChatMessage();
          message.message = m.fname + " " + m.lname + " was added.";
          message.sent = false;
          message.time = details.time;
          message.action = true;
          currentChat = this.pushNewGroupMessageToChat(c, message);
          c.with.members.push(m);
        })
        return false;
      }
    })
    if (currentChat) {
      this.categorizeChatMessages(this.selectedChat.messages, false, true);
    }
  }

  onReceiveToggleGroupAdmin(data){
    var details: { code: string, uid: string, status: boolean} = JSON.parse(data);
    this.main_chats.every((c) => {
      if(c.code.toLowerCase() == details.code.toLowerCase()){
        c.with.members.every((m) => {
          if(m.id == details.uid){
            m.isAdmin = details.status;
            return false;
          }
        })
        return false;
      }
    })
  }

  orderChatsDescending() {
    this.main_chats.sort((a, b) => {
      var atime = a.lastmsg ? a.lastmsg.time : undefined;
      var btime = b.lastmsg ? b.lastmsg.time : undefined;
      if (!atime && a.with.type == ChatWithType.Group) {
        atime = a.created;
      }
      if (!btime && b.with.type == ChatWithType.Group) {
        btime = b.created;
      }
      return moment(btime).diff(moment(atime));
    })
    if (this.searchValue.trim() == "") {
      this.chats = this.main_chats;
    }
  }

  ngOnInit() {
    //this.getFakeChat();
    this.loadChatList();
  }
  ngAfterViewChecked() {
    //this.scrollChatToBottom();
  }
  setHubConnection(hub: HubConnection) {
    this.hubConnection = hub;
  }
  clearChatSearch() {
    this.searchValue = "";
    this.chats = this.main_chats;
  }
  searchChats() {
    if (this.searchValue.trim() != "") {
      this.chats = this.main_chats.filter((c) => {
        return c.with.fname.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0 || c.with.lname.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0
      });
    } else {
      this.chats = this.main_chats;
    }
  }

  loadChatList() {
    this.network.sendRequest({}, NetworkService.GET_CHATS).subscribe((res) => {
      if (!res['error']) {
        this.main_chats = JSON.parse(res['response']);
        this.orderChatsDescending();
        this.main_chats.forEach((c) => {
          if(c.lastmsg){
            if (c.lastmsg.attachedFile && !c.lastmsg.attachedFile.type) {
              c.lastmsg.attachedFile.type = this.getFileTypeFromMimeType(mime.getType(c.lastmsg.attachedFile.previewLink))
            }
          }
        });
        this.chats = this.main_chats;
      } else {
      }
      this.cl_loader = false;
    }, (error) => {
    })
  }

  getFakeChat() {
    for (let index = 1; index < 11; index++) {
      var chat = new Chat();
      var chatWith = new ChatWith();
      chatWith.id = index.toString();
      chatWith.type = ChatWithType.Private;
      if (index % 2 == 0) {
        chatWith.email = "user" + index + "@gmail.com";
        chatWith.fname = "User";
        chatWith.lname = index.toString();
        chatWith.color = "#3456FD";
      } else {
        chatWith.email = "userodd" + index + "@gmail.com";
        chatWith.fname = "Userodd";
        chatWith.lname = index.toString();
        chatWith.color = "#5876FD";
      }
      chat.with = chatWith;
      chat.unread = 5;
      var messages = new Array<ChatMessage>();
      for (let index = 1; index < 10; index++) {
        var message = new ChatMessage();
        message.id = index;
        message.message = "Lorem Ipsum Something is wrong with Lorem, Ipsim";
        message.time = moment().toString();
        message.sent = true;
        if (index % 3 == 0) {
          message.sent = false
        }
        messages.push(message);
      }
      chat.lastmsg = messages[messages.length - 1];
      chat.messages = messages;
      this.chats.push(chat);
    }
  }

  selectChat(chat: Chat) {
    if (chat) {
      this.hideChatInfo();
      this. closeAttachmentsPreview();
      this.selectedChat = chat;
      this.selectedChat.scrollUnread = 0;
      if (!this.selectedChat.currentInput) {
        this.selectedChat.currentInput = "";
      }
      if (this.cinput) {
        this.cinput.nativeElement.innerHTML = this.selectedChat.currentInput;
      }
      if (this.selectedChat.unread > 0) {
        this.hubConnection.invoke(SREvent.CLEAR_UNREAD, this.selectedChat.code, this.selectedChat.with.type);
        setTimeout(() => {
          this.selectedChat.unread = 0;
        }, 700);
      }
      this.loadChatMessages(this.selectedChat);
      this.isGroupAdmin();
    }
  }

  loadChatMessages(chat: Chat) {
    if (!chat.messages || chat.messages.length <= 0) {
      this.chat_messages_loader = true;
      this.network.sendRequest({ code: chat.code, type: chat.with.type }, NetworkService.GET_CHAT_MESSAGES).subscribe((res) => {
        if (!res['error']) {
          chat.messages = JSON.parse(res['response']);
          chat.messages = chat.messages.reverse();
          this.categorizeChatMessages(chat.messages, true);
        } else {
          this.categorizeChatMessages(chat.messages, true);
        }
      }, (error) => {
        this.chat_messages_loader = false;
      })
    } else {
      this.categorizeChatMessages(chat.messages);
      this.scrollChatToBottom(5)
    }
  }

  CHAT_PAGE_SIZE: number = 20;
  loadMoreChatMessages(chat: Chat){
    if(chat.messages && chat.messages.length > 0){
      this.network.sendRequest({ code: chat.code, type: chat.with.type, size: chat.messages.length }, NetworkService.GET_CHAT_MESSAGES).subscribe((res) => {
        if (!res['error']) {
          var messages: any[] = JSON.parse(res['response']);
          //messages = messages.reverse();
          messages.forEach((m) => {
            chat.messages.unshift(m);
          })
          this.categorizeChatMessages(chat.messages);
          if(this.CHAT_PAGE_SIZE == messages.length){
            if(this.bubbles){
              this.bubbles.nativeElement.scrollTop = (20/100) * (this.bubbles.nativeElement.scrollHeight - this.bubbles.nativeElement.offsetHeight);
            }
          }else if(messages.length == 0 || messages.length < this.CHAT_PAGE_SIZE){
            chat.completeLoad = true;
          }
        } else {
          this.categorizeChatMessages(chat.messages);
        }
      }, (error) => {
      })
    }
  }

  categorizeChatMessages(messages: Array<ChatMessage>, showLoader: boolean = false, addedNew: boolean = false) {
    if(showLoader){
      this.chat_messages_loader = true;
      this.scrollChatToBottom(1);
    }
    this.categorizedMessages = new Array();
    var tempCategprizedMessage: {category: string, messages: ChatMessage[]}[] = []
    if (messages) {
      messages.forEach((m) => {
        if (m.time) {
          var key = this.getChatTime(m.time, true);
          if (key) {
            var cm_msgs = this.categorizedMessages.find((cm) => {
              return cm.category == key;
            });
            if (cm_msgs) {
              cm_msgs.messages.push(m);
            } else {
              cm_msgs = { category: key, messages: new Array<ChatMessage>() }
              cm_msgs.messages.push(m);
              this.categorizedMessages.push(cm_msgs);
            }
          }
        }
        if (m.attachedFile && !m.attachedFile.type) {
          m.attachedFile.type = this.getFileTypeFromMimeType(mime.getType(m.attachedFile.previewLink))
        }
        //console.log("loop")
        //this.scrollChatToBottom(1);
      })
    }

    if(showLoader){
      setTimeout(() => {
        this.chat_messages_loader = false;
      }, 500);
      this.scrollChatToBottom(50)
    }
    setTimeout(() => {
      this.checkScrollDownButton();
      if(addedNew && this.showScrollDown){
        this.selectedChat.scrollUnread += 1;
      }
    }, 700);
  }
  getChatTime(datetime: string, period?) {
    if (!datetime || datetime.trim() == "") {
      return "";
    }
    var today = moment();
    var da = moment(datetime);
    var dar = moment(da.format("YYYY-MM-DD"));
    if (today.diff(dar, 'days') <= 0) {
      //today => get only time
      if (period) {
        return "Today";
      }
      return da.format('h:mm a') //e.g 7:02 pm
    } else if (today.diff(dar, 'days') == 1) {
      return "Yesterday";
    } else {
      return da.format('M/D/YY'); //ex: 6/30/19;
    }
  }

  getMsgTime(datetime: string) {
    var da = moment(datetime);
    return da.format('h:mm a')
  }

  getBadgeColor(chat: Chat) {
    return chat.with.color;
  }

  getChatInitials(chat: Chat) {
    if (chat.with.type == ChatWithType.Private) {
      return chat.with.fname.charAt(0).toUpperCase() + chat.with.lname.charAt(0).toUpperCase();
    } else {
      var names = chat.with.name.split(" ");
      if (names.length > 1) {
        return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
      } else {
        return chat.with.name.charAt(0).toUpperCase();
      }
    }
  }

  scrollChatToBottom(millis?: number) {
    if(millis == null){
      millis = 50;
    }
    setTimeout(() => {
      try {
        //console.log("called")
        //this.bubbles.nativeElement.scrollTop = (this.bubbles.nativeElement.scrollHeight - this.bubbles.nativeElement.offsetHeight);
        this.bubbles.nativeElement.scrollTop = (this.bubbles.nativeElement.scrollHeight);
        if(this.selectedChat){
          this.selectedChat.scrollUnread = 0;
        }
      } catch (err) { }
    }, millis);
  }

  showScrollDown: boolean = false;
  onBubblesScroll() {
    try {
      if (this.bubbles) {
        this.checkScrollDownButton();
        if(this.bubbles.nativeElement.scrollTop == 0 && !this.selectedChat.completeLoad){
          this.loadMoreChatMessages(this.selectedChat);
        }
        return;
      }
    } catch (error) { }
    this.showScrollDown = false;
  }

  checkScrollDownButton(){
    this.showScrollDown = this.bubbles.nativeElement.scrollTop < (this.bubbles.nativeElement.scrollHeight - this.bubbles.nativeElement.offsetHeight) - 30;
  }

  chatInputKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 16) {
      this.shiftKeyDown = true;
    }
  }

  shiftKeyDown: boolean = false;
  chatInputKeyUp(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      if (!this.shiftKeyDown) {
        event.preventDefault();
      } else {
        //send chat
      }
    } else if (event.keyCode == 16) {
      this.shiftKeyDown = false;
    }
  }

  chatInputKeyPress(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      if (event.shiftKey) {
        //put new line
      } else {
        event.preventDefault();
        this.sendChat();
      }
    }
  }
  chatInput() {
    this.selectedChat.currentInput = this.cinput.nativeElement.innerHTML;
  }

  institutionsChange(event) {
    this.refreshInstitutionUsers();
    var intId = event.value;
    if (event.value) {
      if (!this.sel_institution.users || this.sel_institution.users.length <= 0) {
        this.institutions_users_load_async = true;
        this.network.sendRequest({ did: event.value.id }, NetworkService.GET_DEPARTMENT_USERS).subscribe((res) => {
          this.institutions_users_load_async = false
          if (!res['error']) {
            var usrs = JSON.parse(res['response']);
            this.institution_users = usrs;
          }
        }, (error) => {
          this.institutions_users_load_async = false
        });
      } else {
        this.institution_users = this.sel_department.users
      }
    }
  }

  refreshInstitutionUsers() {
    this.toggle_users_dropdown = false;
    this.selected_group_users = null;
    this.institution_users = new Array();
    setTimeout(() => {
      this.toggle_users_dropdown = true;
    }, 1);
  }

  toggle_institutions: boolean = true;
  loadInstitutions(team?: boolean) {
    this.refreshInstitutionUsers();
    if (this.institutions.length <= 0) {
      this.institutions_load_async = true;
      this.network.sendRequest({}, NetworkService.GET_DEPARTMENTS).subscribe((res) => {
        this.institutions_load_async = false
        if (!res['error']) {
          var deps = JSON.parse(res['response']);
          this.institutions = deps;
          this.toggle_institutions = false;
          setTimeout(() => {
            this.toggle_institutions = true;
          }, 1);
        }
      }, (error) => {
        this.institutions_load_async = false;
      })
    }
  }

  toggle_departments: boolean = true;
  loadDepartments() {
    this.refreshDepartmentUsers();
    if (this.institutions.length <= 0) {
      this.departments_load_async = true;
      this.network.sendRequest({}, NetworkService.GET_DEPARTMENTS).subscribe((res) => {
        this.departments_load_async = false
        if (!res['error']) {
          var deps = JSON.parse(res['response']);
          this.departments = deps;
          this.toggle_departments = false;
          setTimeout(() => {
            this.toggle_departments = true;
          }, 1);
        }
      }, (error) => {
        this.departments_load_async = false;
      })
    }
  }

  departmentChange(event) {
    this.refreshDepartmentUsers();
    this.sel_department = event.value;
    if (event.value) {
      if (!this.sel_department.users || this.sel_department.users.length <= 0) {
        this.department_users_load_async = true;
        this.network.sendRequest({ did: event.value.id }, NetworkService.GET_DEPARTMENT_USERS).subscribe((res) => {
          this.department_users_load_async = false
          if (!res['error']) {
            var usrs = JSON.parse(res['response']);
            this.department_users = usrs;
            var department = this.departments.find((d) => {
              return d.id == this.sel_department.id;
            })
            department.users = usrs;
          }
        }, (error) => {
          this.department_users_load_async = false
        });
      } else {
        this.department_users = this.sel_department.users
      }
    }
  }

  refreshDepartmentUsers() {
    this.toggle_users_dropdown = false;
    this.selected_chat_user = null;
    this.current_selected_group_users = new Array();
    this.department_users = new Array();
    setTimeout(() => {
      this.toggle_users_dropdown = true;
    }, 1);
  }

  refreshDropDowns(department?: boolean) {
    if (department) {
      this.toggle_departments = false;
      this.departments = new Array();
      setTimeout(() => {
        this.toggle_departments = true;
      }, 1);
    }
    this.refreshDepartmentUsers();
  }

  loadInstitutionsData() {
    this.refreshDropDowns(true);
    if (this.institution_data.length <= 0) {
      this.institutions_load_async = true;
      this.network.sendRequest({}, NetworkService.GET_INSTITUTIONS).subscribe((res) => {
        this.institutions_load_async = false
        if (!res['error']) {
          var deps = JSON.parse(res['response']);
          this.institution_data = deps;
          this.toggle_institutions = false;
          setTimeout(() => {
            this.toggle_institutions = true;
          }, 1);
        }
      }, (error) => {
        this.institutions_load_async = false;
      })
    }
  }

  institutionChange(event) {
    this.refreshDropDowns(true);
    var selInst = event.value;
    if (!selInst) {
      return;
    }
    this.departments = selInst["departments"];
    this.departments_load_async = true;
    if (!this.departments || this.departments.length <= 0) {
      this.network.sendRequest({ iid: selInst.id }, NetworkService.GET_INSTITUTION_DEPARTMENTS).subscribe((res) => {
        if (!res['error']) {
          var deps = JSON.parse(res['response']);
          var index = this.institution_data.findIndex((_i) => {
            return _i.id == selInst.id;
          });
          if (index >= 0) {
            this.institution_data[index]["departments"] = deps;
          }
          this.departments = deps;
          this.toggle_departments = false;
          setTimeout(() => {
            this.toggle_departments = true;
          }, 1);
        }
        this.departments_load_async = false;
      }, (error) => {
        this.departments_load_async = false;
      })
    } else {
      this.toggle_departments = false;
      setTimeout(() => {
        this.toggle_departments = true;
      }, 1);
    }
  }

  deptChange(event) {
    this.refreshDropDowns();
    var selDept = event.value;
    if (!selDept) {
      return;
    }
    this.department_users = selDept['users'];
    this.department_users_load_async = true;
    if (!this.department_users || this.department_users.length <= 0) {
      this.network.sendRequest({ did: selDept.id }, NetworkService.GET_DEPARTMENT_USERS).subscribe((res) => {
        if (!res['error']) {
          var users = JSON.parse(res['response']);
          var index = this.institution_data.findIndex((_i) => {
            return _i.id == selDept.iid;
          });
          var dIndex = this.institution_data[index].departments.findIndex((d) => {
            return d.id == selDept.id;
          })
          if (index >= 0) {
            this.institution_data[index].departments[dIndex]['users'] = users;
          }
          this.department_users = users;
          this.toggle_users_dropdown = false;
          setTimeout(() => {
            this.toggle_users_dropdown = true;
          }, 1);
        }
        this.department_users_load_async = false;
      }, (error) => {
        this.department_users_load_async = false;
      })
    } else {
      this.department_users_load_async = false;
      this.toggle_users_dropdown = false;
      setTimeout(() => {
        this.toggle_users_dropdown = true;
      }, 1);
    }
  }

  addSelectedGroupMemberToList() {
    this.current_selected_group_users.forEach((cu) => {
      var findUser = this.selected_group_users.find((u) => {
        return u.id == cu.id;
      })
      if (!findUser) {
        this.selected_group_users.push(cu);
      }
    });
    this.refreshDepartmentUsers();
  }

  removeAddGroupMember(mem) {
    this.selected_group_users = this.selected_group_users.filter((m) => {
      return m.id != mem.id;
    })
  }

  setAddChatTab(tab: number) {
    if (tab == 0) {
      if (this.create_group_async) {
        return;
      }
      this.current_add_chat_type_tab = tab;
    } else if (tab == 1) {
      if (this.add_user_async) {
        return;
      }
      this.current_add_chat_type_tab = tab;
    }
  }

  checkTextInput() {
    if (!this.cinput) {
      return false;
    }
    //console.log(this.cinput.nativeElement.innerHTML);
    return (this.cinput.nativeElement.innerHTML == "");

  }

  createChatGroup() {
    if (this.newGroupName.trim() == '') {
      this.alertHelper.showOkAlert("Missing group name", "Please enter a name for the group");
    } else if (this.selected_group_users.length < 2) {
      this.alertHelper.showOkAlert("No group member", "Please add atleast two members to the group");
    } else {
      this.create_group_async = true;
      var mems = [];
      this.selected_group_users.forEach((gu) => {
        mems.push(gu.id);
      });
      this.network.sendRequest({ gname: this.newGroupName, mems: mems }, NetworkService.CREATE_GROUP_CHAT).subscribe((res) => {
        if (!res['error']) {
          var chat = new Chat();
          var cWith = new ChatWith();
          cWith.type = ChatWithType.Group;
          var members = [];
          members = this.selected_group_users;
          var you = { id: "", name: "You", fname: this.loggedUser.FirstName, lname: this.loggedUser.LastName, email: this.loggedUser.email, color: this.loggedUser.UserColour, position: this.loggedUser.Position, isAdmin: true, self: true };
          members.push(you);
          cWith.members = members;
          cWith.name = this.newGroupName;

          chat.with = cWith;
          chat.currentInput = "";
          chat.unread = 0;
          chat.code = res['response'];

          this.main_chats.unshift(chat);
          if (this.searchValue.trim() == "") {
            this.chats = this.main_chats;
            this.selectChat(this.main_chats[0]);
            this.scrollIntoActiveChat();
          }
          this.add_user = false;
          this.refreshDropDowns(true);
          this.selected_group_users = []
          this.alertHelper.showOkAlert("Success", "Created group successfully");
        } else {
          this.alertHelper.showOkAlert("Error", res['response']);
        }
        this.create_group_async = false;
      }, (error) => {
        this.alertHelper.showErrorAlert();
        this.create_group_async = false;
      });
    }
  }

  startNewChat() {
    if (this.selected_chat_user) {
      var check_user = this.main_chats.find((c) => {
        return c.with.id == this.selected_chat_user.id;
      })
      if (check_user) {
        this.selectChat(check_user);
        this.scrollIntoActiveChat();
      } else {
        var chat = new Chat();
        var cWith = new ChatWith();
        cWith.fname = this.selected_chat_user.fname;
        cWith.lname = this.selected_chat_user.lname;
        cWith.color = this.selected_chat_user.color;
        cWith.email = this.selected_chat_user.email;
        cWith.position = this.selected_chat_user.position;
        cWith.id = this.selected_chat_user.id;
        chat.with = cWith;
        chat.currentInput = "";
        chat.unread = 0;
        this.main_chats.unshift(chat);
        if (this.searchValue.trim() == "") {
          this.chats = this.main_chats;
        }
        this.selectChat(this.main_chats[0]);
        this.scrollIntoActiveChat();
      }
      this.add_user = false;
    } else {
      this.alertHelper.showOkAlert("Error", "Please select a user");
    }
  }

  scrollIntoActiveChat() {
    setTimeout(() => {
      $('.people').animate({
        scrollTop: $("#act-chat").offset().top
      }, 1500);
    }, 200);
  }

  sendChat() {
    if (!this.cinput.nativeElement.childNodes[0].data.trim()) {
      return;
    } else {
      this.selectedChat.currentInput = this.cinput.nativeElement.childNodes[0].data.trim()
    }
    if (this.selectedChat.currentInput) {
      if (this.selectedChat.currentInput.trim() != "") {
        var messageText = this.selectedChat.currentInput.trim();
        var code = this.selectedChat.code;
        var withId = this.selectedChat.with.id;
        var message = new ChatMessage();
        message.message = messageText;
        message.sent = true;
        message.time = moment().toString();
        this.selectedChat.currentInput = "";
        this.setChatInput();
        this.selectedChat.messages.push(message);
        this.selectedChat.lastmsg = message;
        this.categorizeChatMessages(this.selectedChat.messages);
        this.orderChatsDescending();
        this.scrollChatToBottom();
        if (this.hubConnection) {
          if (this.selectedChat.with.type == ChatWithType.Private) {
            this.hubConnection.invoke(SREvent.SEND_CHAT, withId, messageText, code).catch(err => { console.log(err) }).finally(() => {

            });
          } else if (this.selectedChat.with.type == ChatWithType.Group) {
            this.hubConnection.invoke(SREvent.SEND_GROUP_CHAT, messageText, code).catch(err => { console.log(err) }).finally(() => {

            });
          }
        }
      }
    }
  }

  setChatInput() {
    if (this.cinput) {
      this.cinput.nativeElement.innerHTML = this.selectedChat.currentInput;
    }
  }

  showChatInfo() {
    this.chat_info = true;
    this.selectedChatGroupName = this.selectedChat.with.name;
  }
  hideChatInfo() {
    this.chat_info = false;
    this.groupNameEditMode = false;
  }

  isGroupAdmin() {
    if (this.selectedChat && this.selectedChat.with.type == ChatWithType.Group) {
      var findSelf = this.selectedChat.with.members.find((m) => {
        return m.isAdmin && m.self;
      })
      this.isSelectedChatGroupAdmin = findSelf ? true : false;
      return;
    }
    this.isSelectedChatGroupAdmin = false;
  }

  toggleGroupAdmin(member: GroupMember) {
    if (this.selectedChat && this.selectedChat.with.type == ChatWithType.Group) {
      var wasAdmin = member.isAdmin;
      this.member_list_async_action.push(member.id);
      this.network.sendRequest({ code: this.selectedChat.code, uid: member.id }, NetworkService.TOGGLE_GROUP_ADMIN).subscribe((res) => {
        if (!res['error']) {
          member.isAdmin = !wasAdmin;
          this.alertHelper.showOkAlert('Success', res['response']);
        } else {
          this.alertHelper.showOkAlert('Error', res['response']);
        }
        this.removeAsyncMemberList(member.id)
      }, (error) => {
        this.removeAsyncMemberList(member.id)
        this.alertHelper.showErrorAlert();
      })
    }
  }

  removeAsyncMemberList(id) {
    this.member_list_async_action = this.member_list_async_action.filter((m) => {
      return m != id;
    });
  }

  addUsersToGroupChat() {
    if (this.selectedChat && this.selectedChat.with.type == ChatWithType.Group) {
      if (this.selected_group_users.length < 1) {
        this.alertHelper.showOkAlert("No group member", "Please add atleast one user to the group");
      } else {
        this.add_group_member_async = true;
        var mems = [];
        this.selected_group_users.forEach((gu) => {
          mems.push(gu.id);
        });
        this.network.sendRequest({ code: this.selectedChat.code, mems: mems }, NetworkService.ADD_GROUP_CHAT_MEMBERS).subscribe((res) => {
          if (!res['error']) {
            var members: GroupMember[] = [];
            members = this.selected_group_users;
            members.forEach((m) => {
              var find = this.selectedChat.with.members.find((wm) => {
                return wm.id == m.id;
              });
              if (!find) {
                m.isAdmin = false;
                m.self = false;
                this.selectedChat.with.members.push(m);
              }
            });
            this.add_group_member = false;
            this.refreshDropDowns(true);
            this.selected_group_users = []
            this.alertHelper.showOkAlert("Success", "Added users to group successfully");
          } else {
            this.alertHelper.showOkAlert("Error", res['response']);
          }
          this.add_group_member_async = false;
        }, (error) => {
          this.alertHelper.showErrorAlert();
          this.create_group_async = false;
        });
      }
    }
  }

  confirmRemoveUserFromGroup(user: GroupMember) {
    this.alertHelper.showYesNoAlert('Confirm', 'Are you sure want to remove this user?', () => { this.removeUserFromGroup(user) })
  }
  removeUserFromGroup(user: GroupMember) {
    if (this.selectedChat && this.selectedChat.with.type == ChatWithType.Group) {
      this.member_list_async_action.push(user.id);
      this.network.sendRequest({ code: this.selectedChat.code, uid: user.id }, NetworkService.REMOVE_GROUP_CHAT_MEMBER).subscribe((res) => {
        if (!res['error']) {
          this.selectedChat.with.members = this.selectedChat.with.members.filter((m) => {
            return m.id != user.id;
          })
          this.alertHelper.showOkAlert("Success", res['response'])
        } else {
          this.alertHelper.showOkAlert("Error", res['response'])
        }
        this.removeAsyncMemberList(user.id);
      }, (error) => {
        this.alertHelper.showErrorAlert();
        this.removeAsyncMemberList(user.id);
      })
    }
  }

  confirmExitGroup() {
    this.alertHelper.showYesNoAlert('Confirm', 'Are you sure want to exit group?', () => { this.exitGroup() })
  }

  chat_action_async: boolean = false
  exitGroup() {
    if (this.selectedChat && this.selectedChat.with.type == ChatWithType.Group) {
      var you = this.selectedChat.with.members.find((m) => {
        return m.self;
      });
      if (you) {
        this.chat_action_async = true;
        this.network.sendRequest({ code: this.selectedChat.code }, NetworkService.EXIT_GROUP_CHAT).subscribe((res) => {
          if (!res['error']) {
            this.removeChat(this.selectedChat);
            this.alertHelper.showOkAlert('Success', res['response']);
          } else {
            this.alertHelper.showOkAlert('Error', res['response']);
          }
          this.chat_action_async = false;
        }, (error) => {
          this.alertHelper.showErrorAlert();
          this.chat_action_async = false;
        })
      }
    }
  }

  removeChat(chat: Chat) {
    this.main_chats = this.main_chats.filter((c) => {
      return c.code != chat.code
    })
    if (this.searchValue.trim() == "") {
      this.chats = this.main_chats;
    }
    if (this.selectedChat.code == chat.code) {
      this.selectedChat = null;
    }
  }

  attachFilesClick() {
    var files: HTMLInputElement = this.attachFileInp.nativeElement;
    files.click();
  }



  selected_attachments: Array<FileAttachment> = new Array();
  max_number_of_attachments: number = 5;
  image_files_types = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml"];
  audio_files_types = ["audio/mp3", "audio/mpeg"];
  video_files_types = ["video/mp4"];
  other_files_types_icons = {
    "application/pdf": "assets/img/ic_pdf.png",
    "application/msword": "assets/img/ic_word.png",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "assets/img/ic_word.png",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "assets/img/ic_ppt.png"
  }
  generic_file_icon = "assets/img/ic_generic_file.svg";
  audio_file_icon = "assets/img/ic_audio_file.svg";
  video_file_icon = "assets/img/ic_video_file.svg";
  selected_attachment_preview: FileAttachment;

  attachFilesChange() {
    var files = this.attachFileInp.nativeElement;
    var fileError: boolean = false;
    if (files.files) {
      var total_file_count = this.selected_attachments.length + files.files.length;
      //if(total_file_count <= this.max_number_of_attachments){
      this.preview_attachment = true;
      for (var i = 0; i < files.files.length; i++) {
        var prevLink = window.URL.createObjectURL(files.files[i]);
        var type = this.getFileTypeFromMimeType(files.files[i].type);

        if (this.other_files_types_icons[files.files[i].type] || type == AttachedFileType.Other) {
          //prevLink = this.other_files_types_icons[files.files[i].type];
          prevLink = this.generic_file_icon;
        }

        this.selected_attachments.push({ file: files.files[i], previewLink: prevLink, type: type, id: Utils.createGUID(), caption: "" });
      }
      this.selected_attachment_preview = this.selected_attachments[this.selected_attachments.length - 1];
      setTimeout(() => {
        if (this.nails) {
          this.nails.nativeElement.scrollLeft = this.nails.nativeElement.scrollWidth;
        }
      }, 200);
      if (fileError) {
      }
      // }else{
      // }
      files.value = "";
    } else {
      //this.selected_attachments = new Array();
    }
  }

  getDocumentAttachmentIcon(attach: FileAttachment) {
    // var type = attach.file ? attach.file.type : mime.getType(attach.previewLink);
    // return this.other_files_types_icons[type] ? this.other_files_types_icons[type] : this.generic_file_icon;
    return this.generic_file_icon;
  }
  getDocumentAttachmentFileName(attach: FileAttachment) {
    return attach.file ? attach.file.name : attach.name;
  }

  getFileTypeFromMimeType(mType) {
    if (this.image_files_types.indexOf(mType) >= 0) {
      //Image file
      return AttachedFileType.Image;
    } else if (this.audio_files_types.indexOf(mType) >= 0) {
      //audio file
      return AttachedFileType.Audio;
    } else if (this.video_files_types.indexOf(mType) >= 0) {
      //video file
      return AttachedFileType.Video;
    } else if (this.other_files_types_icons[mType]) {
      //generic file;
      return AttachedFileType.Document;
    } else {
      return AttachedFileType.Other;
    }
  }

  nailClick(nail: HTMLDivElement) {
    nail.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
  }

  removeAttachment(attach: FileAttachment) {
    if (this.selected_attachments.length == 1) {
      this.closeAttachmentsPreview();
    } else {
      var index = this.selected_attachments.findIndex((a) => {
        return a.id == attach.id;
      })
      if (index >= 0) {
        if (index == this.selected_attachments.length - 1) {
          index -= 1;
        }
        this.selected_attachments = this.selected_attachments.filter((a) => {
          return a.id != attach.id;
        })
        this.selected_attachment_preview = this.selected_attachments[index];
      }
    }
  }

  getAttachedImageBackground() {
    return this.sanitizer.bypassSecurityTrustStyle('url( ${this.selected_attachment_preview.previewLink})');
  }

  closeAttachmentsPreview() {
    this.preview_attachment = false;
    this.selected_attachments = new Array();
    this.selected_attachment_preview = null;
  }

  tempAttachments: Array<FileAttachment> = new Array();
  sendAttachments() {
    if (this.selected_attachments.length > 0) {
      this.tempAttachments = this.selected_attachments;
      this.closeAttachmentsPreview();
      this.tempAttachments.forEach((attach) => {
        var code = this.selectedChat.code;
        var withId = this.selectedChat.with.id;
        var message = new ChatMessage();
        //message.message = messageText;
        message.sent = true;
        message.time = moment().toString();
        attach.uploadStatus = FileAttachmentUploadStatus.Uploading;
        attach.uploadProgress = 0;
        message.attachedFile = attach
        // this.selectedChat.currentInput = "";
        // this.setChatInput();
        this.selectedChat.messages.push(message);
        //message.attachedFile.uploadObservable = this.network.fileUpload(attach.file, NetworkService.UPLOAD_CHAT_ATTACHMENT, {code: this.selectedChat.code})
        //var upReq: Observable<any> = this.network.fileUpload(attach.file, NetworkService.UPLOAD_CHAT_ATTACHMENT, {code: this.selectedChat.code})
        // upReq.pipe(map(res => {
        //   if(message.attachedFile.uploadStatus == FileAttachmentUploadStatus.Cancelled){
        //     message.attachedFile.file = null;
        //   }else{
        //     this.fileAttachProgress(res, message);
        //   }
        // }), catchError((err) =>{
        //   // var err: HttpErrorResponse = new HttpErrorResponse({status: 1000});
        //   // //throw throwError(err);
        //   return Observable.throw(err);
        // }));
        // var sub: Subscription = this.network.fileUpload(attach.file, NetworkService.UPLOAD_CHAT_ATTACHMENT, {code: this.selectedChat.code}).subscribe((res) => {
        //   if(message.attachedFile.uploadStatus == FileAttachmentUploadStatus.Cancelled){
        //     sub.unsubscribe();
        //   }else{
        //     this.fileAttachProgress(res, message);
        //   }
        //   //this.fileAttachProgress(res, message);
        // }, (error) => {
        //   console.log(attach);
        //   message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Cancelled;
        // })
        this.sendAttachment(message);
      });
      this.selectedChat.lastmsg = this.selectedChat.messages[this.selectedChat.messages.length - 1];
      this.categorizeChatMessages(this.selectedChat.messages);
      this.orderChatsDescending();
      this.scrollChatToBottom();
    }
  }

  fileAttachProgress(event, message: ChatMessage) {
    if (event.type == HttpEventType.UploadProgress) {
      message.attachedFile.uploadProgress = Math.round(100 * event.loaded / event.total);
      // if(message.attachedFile.uploadProgress >= 2){
      //   var err: HttpErrorResponse = new HttpErrorResponse({status: 1000});
      //   //throw throwError(err);
      //   return Observable.throw(err);
      // }
    } else if (event.type == HttpEventType.Response) {

      var serverResponse = event.body;
      if (!serverResponse['error']) {
        this.tempAttachments = this.tempAttachments.filter((a) => {
          return a.id != message.attachedFile.id;
        })
        message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Uploaded;
        var response = JSON.parse(serverResponse['response']);
        message.attachedFile.previewLink = response['plink'];
        message.id = response['id'];
      }else{
        message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Cancelled;
      }
    }
  }

  sendAttachment(message: ChatMessage) {
    message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Uploading;
    message.attachedFile.uploadProgress = 0;
    var sub: Subscription = this.network.fileUpload(message.attachedFile.file, NetworkService.UPLOAD_CHAT_ATTACHMENT, { code: this.selectedChat.code, with: this.selectedChat.with.id.toLowerCase(), type: this.selectedChat.with.type }).subscribe((res) => {
      if (message.attachedFile.uploadStatus == FileAttachmentUploadStatus.Cancelled) {
        sub.unsubscribe();
      } else {
        this.fileAttachProgress(res, message);
      }
    }, (error) => {
      message.attachedFile.uploadStatus = FileAttachmentUploadStatus.Cancelled;
    })
  }

  emojiClick(event: EmojiEvent) {
    console.log(event);
    if (this.cinput) {
      this.cinput.nativeElement.innerHTML += event.emoji.native;
      this.chatInput();
    }
    // var span = event.$event.target;
    // var style = span.currentStyle || window.getComputedStyle(span);
    // var url = style.backgroundImage.slice(4, -1).replace(/["']/g, "");
    // console.log(url);
    // var img = new Image();
    // var _this = this;
    // img.crossOrigin = "Anonymous";
    // img.onload = function(){
    //   console.log(_this.getDataURL(img));
    // }
    // img.src = url;
  }

  getDataURL(img) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/jpeg', 0.1);
  };

  previewChatImage(attach: FileAttachment) {
    this.selectedPreviewImage = attach;
    this.previewImage = true;
  }

  downloadAttachment(attach: FileAttachment) {
    saveAs(attach.previewLink);
  }

  changeGroupName(chat: Chat) {
    if (chat && chat.with.type == ChatWithType.Group) {
      if (this.selectedChatGroupName.trim() != chat.with.name.trim()) {
        var _gName = this.selectedChatGroupName.trim();
        this.groupNameEditAsync = true;
        this.network.sendRequest({ code: chat.code, name: _gName }, NetworkService.CHANGE_GROUP_NAME).subscribe((res) => {
          if (!res['error']) {
            this.groupNameEditMode = false;
            chat.with.name = _gName;
          } else {
            this.alertHelper.showOkAlert("Error", res['response']);
          }
          this.groupNameEditAsync = false
        }, (error) => {
          this.groupNameEditAsync = false
          this.alertHelper.showErrorAlert();
        })
      } else {
        this.groupNameEditMode = false;
      }
    }
  }
}

enum AttachedFileType {
  Image = 1,
  Audio = 2,
  Video = 3,
  Document = 4,
  Other = 5
}

class Chat {
  code: string = "";
  with: ChatWith = new ChatWith();
  messages: Array<ChatMessage> = new Array();
  lastmsg: ChatMessage = new ChatMessage();
  unread: number = 0;
  scrollUnread: number = 0;
  currentInput: string = "";
  created: string = "";
  completeLoad: boolean = false;
}

class ChatWith {
  id: string;
  name?: string = "";
  fname?: string = "";
  lname?: string = "";
  email?: string = "";
  color?: string = "";
  type: ChatWithType = ChatWithType.Private;
  position?: string = "";
  members?: GroupMember[] = new Array();
  photo?: string;
}

enum ChatWithType {
  Private = 1,
  Group = 2
}

class ChatMessage {
  id: number;
  message: string = "";
  sent: boolean = false;
  time: string = "";
  byUsr?: string;
  attachedFile?: FileAttachment;
  action?: boolean = false
}

class GroupMember {
  id: string;
  name: string;
  fname: string;
  lname: string;
  email: string;
  color: string;
  position: string;
  isAdmin?: boolean;
  self?: boolean;
}

class FileAttachment {
  file?: File;
  name?: string;
  previewLink: string;
  type?: number;
  id?: string;
  caption?: string;
  uploadStatus?: FileAttachmentUploadStatus = FileAttachmentUploadStatus.Uploading;
  uploadProgress?: number = 0;
  uploadObservable?: Observable<HttpEvent<unknown>>;
}

enum FileAttachmentUploadStatus {
  Cancelled = 0,
  Uploading = 1,
  Uploaded = 2
}

